import {Component, OnInit} from '@angular/core';
import {BaseEditPageComponent} from '../../core/base-classes/base-edit-page.component';
import {BasePageComponent} from '../../core/base-classes/base-page.component';
import {Title} from '@angular/platform-browser';
import {DtoOutAllProject, ProjectService} from '../../generated';

@Component({
  selector: 'app-project-list-page',
  templateUrl: './project-list-page.component.html',
  styleUrls: ['./project-list-page.component.scss']
})
export class ProjectListPageComponent extends BasePageComponent implements OnInit {
  public projects: DtoOutAllProject[];

  constructor(private  title: Title, private projectService: ProjectService) {
    super(title, 'Moje projekty');
  }

  ngOnInit(): void {
    this.refreshMyProjects();
  }

  onRemoveProject() {
    this.refreshMyProjects();
  }

  refreshMyProjects() {
    this.projectService.apiProjectGetAllMyProjectsGet().subscribe(data => {
      this.projects = data;
    });
  }
}
