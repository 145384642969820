import {Component, OnInit} from '@angular/core';
import {DtoOutAllMyReports, TimeService} from '../../generated';
import {Timestamp} from 'rxjs/internal-compatibility';
import {TimeReportChangeNotificationService} from '../../services/changeNotification/time-report-change-notification.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateEditTimeMainReportDialogComponent} from '../../dialogs/create-edit-time-main-report-dialog/create-edit-time-main-report-dialog.component';

@Component({
  selector: 'app-my-work-report-list-page',
  templateUrl: './my-work-report-list-page.component.html',
  styleUrls: ['./my-work-report-list-page.component.scss']
})
export class MyWorkReportListPageComponent implements OnInit {
  public myMonthlyReport: Array<DtoOutAllMyReports> = [];
  public groupedData: { date: Date, reportsForThisDay: Array<DtoOutAllMyReports> }[];

  public reportsFromDate: Date = new Date();
  public hoursForThisMonth: number = 0;
  public minutesForThisMonth: number = 0;

  get year(): number {
    return this._year;
  }

  set year(value: number) {
    this._year = value;
    this.filterLoad();
  }

  get month(): number {
    return this._month;
  }

  set month(value: number) {
    this._month = value;
    this.filterLoad();
  }

  private _month: number;
  private _year: number;

  public availableMonths: { id: number, text: string }[] = [
    {id: 0, text: 'Leden'},
    {id: 1, text: 'Únor'},
    {id: 2, text: 'Březen'},
    {id: 3, text: 'Duben'},
    {id: 4, text: 'Květen'},
    {id: 5, text: 'Červen'},
    {id: 6, text: 'Červenec'},
    {id: 7, text: 'Srpen'},
    {id: 8, text: 'Září'},
    {id: 9, text: 'Říjen'},
    {id: 10, text: 'Listopad'},
    {id: 11, text: 'Prosinec'},
  ];
  public availableYears: number[] = [];

  constructor(private timeService: TimeService, private timeReportChangeNotificationService: TimeReportChangeNotificationService, private dialog: MatDialog) {
    let year = new Date().getFullYear();
    while (year !== 1999) {
      this.availableYears.push(year);
      year = year - 1;
    }
    this._year = new Date().getFullYear();
    this._month = new Date().getMonth();
  }

  ngOnInit(): void {
    this.filterLoad();
  }

  filterLoad() {
    this.timeService.apiTimeGetMyReportsForMonthGet(this.month + 1, this.year).subscribe(data => {
      this.myMonthlyReport = data;
      this.calculateTimeFforMonth();
      this.groupItemsByDay();
    });
    this.timeReportChangeNotificationService.timeReportChanges.subscribe(() => {
      const oldthis = this;
      this.timeService.apiTimeGetMyReportsForMonthGet(this.month + 1, this.year).subscribe(data => {
        oldthis.myMonthlyReport = data;
        oldthis.calculateTimeFforMonth();
        oldthis.groupItemsByDay();
      });
    });
  }

  calculateTimeFforMonth() {
    this.hoursForThisMonth = 0;
    this.minutesForThisMonth = 0;
    this.myMonthlyReport.forEach(item => {
      this.hoursForThisMonth += this.diffHours(new Date(item.dateFrom), new Date(item.dateTo)) / 60 / 60;
    });
    const before = this.hoursForThisMonth;
    this.hoursForThisMonth = Math.floor(this.hoursForThisMonth);
    this.minutesForThisMonth = Math.round((before - this.hoursForThisMonth) * 60);
  }

  groupItemsByDay() {
    this.groupedData = [];
    this.myMonthlyReport.forEach(item => {
      let onlyDate = new Date(item.dateFrom);
      onlyDate.setHours(0, 0, 0, 0);
      let groupedItem = this.groupedData.find(x => x.date.getDate() === onlyDate.getDate());
      if (groupedItem) {
        groupedItem.reportsForThisDay.push(item);
      } else {
        groupedItem = {date: onlyDate, reportsForThisDay: []};
        groupedItem.reportsForThisDay.push(item);
        this.groupedData.push(groupedItem);
      }

    });
    this.sortGroupedItems();
  }

  sortGroupedItems() {
    this.groupedData.forEach(day => {
      day.reportsForThisDay.sort((a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime());
    });
  }

  diffHours(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(diff);
  }

  createNewTimeReport() {
    this.dialog.open(CreateEditTimeMainReportDialogComponent, {data: {}});
  }

}
