import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  DtoOutAllEmployees,
  DtoOutAllMyReports,
  DtoOutProjectReportInfoForProjectManager,
  ProjectService,
  TimeService
} from '../../generated';
import {ngxCsv} from 'ngx-csv';

@Component({
  selector: 'app-employee-time-reports-card',
  templateUrl: './employee-time-reports-card.component.html',
  styleUrls: ['./employee-time-reports-card.component.scss']
})
export class EmployeeTimeReportsCardComponent implements OnInit {
  get allReports(): Array<DtoOutAllMyReports> {
    return this._allReports;
  }

  set allReports(value: Array<DtoOutAllMyReports>) {
    this._allReports = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.dataSource.data = value;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
        // Transform the filter by converting it to lowercase and removing whitespace.
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };
    }
  }

  private _allReports: Array<DtoOutAllMyReports>;

  @Input()
  employeeName: string;

  get employeeId(): number {
    return this._employeeId;
  }

  @Input()
  set employeeId(value: number) {
    this._employeeId = value;
    if (value) {
      this.load();
    }
  }

  get year(): number {
    return this._year;
  }

  set year(value: number) {
    this._year = value;
    if (this._month !== null && this._year) {
      this.filterLoad();
    } else if (this._month === null && this._year == null) {
      this.load();
    }
  }

  get month(): number {
    return this._month;
  }

  set month(value: number) {
    this._month = value;
    if (this._month !== null && this._year) {
      this.filterLoad();
    } else if (this._month === null && this._year == null) {
      this.load();
    }
  }

  private _month: number;
  private _year: number;

  public availableMonths: { id: number, text: string }[] = [
    {id: 0, text: 'Leden'},
    {id: 1, text: 'Únor'},
    {id: 2, text: 'Březen'},
    {id: 3, text: 'Duben'},
    {id: 4, text: 'Květen'},
    {id: 5, text: 'Červen'},
    {id: 6, text: 'Červenec'},
    {id: 7, text: 'Srpen'},
    {id: 8, text: 'Září'},
    {id: 9, text: 'Říjen'},
    {id: 10, text: 'Listopad'},
    {id: 11, text: 'Prosinec'},
  ];
  public moneyInfo: DtoOutProjectReportInfoForProjectManager;

  public availableYears: number[] = [];
  private _employeeId: number;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutAllMyReports>;
  displayedColumns = ['date', 'dateFrom', 'dateTo', 'projectInfo.name', 'projectAction.name', 'description'];

  constructor(private timeService: TimeService, private projectService: ProjectService) {
  }

  ngOnInit(): void {
    let year = new Date().getFullYear();
    while (year !== 1999) {
      this.availableYears.push(year);
      year = year - 1;
    }
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  );

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  filterLoad() {
    if (this.employeeId) {
      this.timeService.apiTimeGetReportsByEmployeeForMonthGet(this.month + 1, this.year, this.employeeId).subscribe(data => {
        this.allReports = data;
      });
      this.projectService.apiProjectGetEmployeeReportInfoForProjectManagerIdEmployeeGet(this.employeeId, this.month + 1, this.year).subscribe(data => {
        this.moneyInfo = data;
      });
    }
  }

  calculateTime(time: number) {
    const before = time;
    let hours = Math.floor(time);
    let minutes = Math.round((before - hours)* 60) ;
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }
    return hours + 'h ' + minutes + ' m';
  }

  load() {
    this.timeService.apiTimeGetReportsByEmployeeForMonthGet(null, null, this.employeeId).subscribe(data => {
      this.allReports = data;
    });
    this.projectService.apiProjectGetEmployeeReportInfoForProjectManagerIdEmployeeGet(this.employeeId, null, null).subscribe(data => {
      this.moneyInfo = data;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportToCsv() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Výkazy projektu',
      useBom: false,
      noDownload: false,
      headers: []
    };
    let data = [];
    this.allReports.forEach(item => {
      let obj = this.flatten(item);
      obj['hours'] = Math.abs(new Date(item.dateFrom).getTime() - new Date(item.dateTo).getTime()) / 36e5;
      delete obj['projectInfo.canEdit'];
      data.push(obj);

    });
    if (data.length !== 0) {
      options.headers.push(Object.keys(data[0]));
    }
    if (this.month !== null && this.year) {
      new ngxCsv(data, 'Výkazy ' + this.employeeName + ' za ' + this.availableMonths.find(x => x.id === this.month).text + ' ' + this.year, options);
    } else {
      new ngxCsv(data, 'Výkazy ' + this.employeeName, options);
    }
  }

  traverseAndFlatten(currentNode, target, flattenedKey) {
    for (let key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        let newKey;
        if (flattenedKey === undefined) {
          newKey = key;
        } else {
          newKey = flattenedKey + '.' + key;
        }

        var value = currentNode[key];
        if (typeof value === 'object') {
          this.traverseAndFlatten(value, target, newKey);
        } else {
          target[newKey] = value;
        }
      }
    }
  }

  flatten(obj) {
    var flattenedObject = {};
    this.traverseAndFlatten(obj, flattenedObject, undefined);
    return flattenedObject;
  }
}
