import {BasePageComponent} from './base-page.component';
import {OnInit, Directive, Component, Inject} from '@angular/core';
import {InfoPage} from '../interface/edit-page';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({template: ''})
export abstract class BaseInfoPageComponent extends BasePageComponent implements OnInit, InfoPage {
  public searchedDataId: number;

  constructor(private titleS: Title, @Inject(String) public titleString: string, private routes: ActivatedRoute) {
    super(titleS, titleString);
  }

  ngOnInit(): void {
    this.routes.params.subscribe(params => {
      if (params['id']) {
        this.searchedDataId = params['id'];
        this.getItem(params['id']);
      }
    });
  }

  public abstract getItem(id: number) ;

}
