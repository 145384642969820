import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {ConfigRoutes} from './core/config/configRoutes';
import {ProjectPageComponent} from './pages/project-page/project-page.component';
import {AuthGuardService} from './core/services/guard/auth-guard.service';
import {ProjectListPageComponent} from './pages/project-list-page/project-list-page.component';
import {MyWorkReportListPageComponent} from './pages/my-work-report-list-page/my-work-report-list-page.component';
import {Role} from './core/role';
import {CreateProjectPageComponent} from './pages/create-project-page/create-project-page.component';
import {EditProjectPageComponent} from './pages/edit-project-page/edit-project-page.component';
import {EmployeesPageComponent} from './pages/employees-page/employees-page.component';
import {EmployeePageComponent} from './pages/employee-page/employee-page.component';


const routes: Routes = [
  {path: '', component: HomePageComponent, pathMatch: 'full'},
  {
    path: ConfigRoutes.projectPage + '/:id',
    component: ProjectPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.Employee]}
  },
  {
    path: ConfigRoutes.myProjecstPage,
    component: ProjectListPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.Employee]}
  },
  {
    path: ConfigRoutes.myWorkReportListPage,
    component: MyWorkReportListPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.Employee]}
  },
  {
    path: ConfigRoutes.createProjectPage,
    component: CreateProjectPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.ProjectManager]}
  },
  {
    path: ConfigRoutes.editProjectPage + '/:id',
    component: EditProjectPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.Employee]}
  },
  {
    path: ConfigRoutes.employees,
    component: EmployeesPageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.WorkingTimeManagementAdmin]}
  },
  {
    path: ConfigRoutes.employee + '/:id',
    component: EmployeePageComponent,
    canActivate: [AuthGuardService],
    data: {roles: [Role.WorkingTimeManagementAdmin]}
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
