import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DtoOutAllEmployees, DtoOutProjectEmployee} from '../../../generated';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {ConfigRoutes} from '../../../core/config/configRoutes';
import {MatDialog} from '@angular/material/dialog';
import {AddProjectToEmployeeDialogComponent} from '../../../dialogs/add-project-to-employee-dialog/add-project-to-employee-dialog.component';

@Component({
  selector: 'app-employee-projects',
  templateUrl: './employee-projects.component.html',
  styleUrls: ['./employee-projects.component.scss']
})
export class EmployeeProjectsComponent implements OnInit {
  get projectEmployees(): Array<DtoOutProjectEmployee> {
    return this._projectEmployees;
  }

  @Input()
  public idEmployee: number;

  @Input()
  set projectEmployees(value: Array<DtoOutProjectEmployee>) {
    this._projectEmployees = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.data = value;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  private _projectEmployees: Array<DtoOutProjectEmployee>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutProjectEmployee>;
  displayedColumns = ['project.name', 'project.description', 'view', 'role'];

  constructor(private router: Router, private dialog: MatDialog) {
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  );

  ngOnInit(): void {
  }

  viewProject(id: number) {
    this.router.navigate([ConfigRoutes.projectPage, id]);
  }

  addEmployeeToProject() {
    this.dialog.open(AddProjectToEmployeeDialogComponent, {data: {idEmployee: this.idEmployee}});
  }
}
