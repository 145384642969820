<div class="header">
  <mat-toolbar class="nav" color="primary">
    <span><button

      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="menuToggle()"
      *ngIf="isHandset$ | async">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button></span>
    <span class="nav-right"></span>
    <span class="nav-right"></span>
    <img *ngIf="picture!=''" alt="profile-photo" class="profile-photo" [src]="picture"/><span
    class="profile-name"> {{ fullName | async}}</span>
    <ng-container *ngIf="(oidcSecurityService.isAuthenticated$ | async)">
      <span> <button mat-icon-button (click)="logoutClick()"><mat-icon
        class="icon">exit_to_app</mat-icon></button></span>

    </ng-container>

    <span class="white-text" *ngIf="!(oidcSecurityService.isAuthenticated$ | async)"> <button mat-button
                                                                                              (click)="loginClick()">Přihlásit se</button></span>
  </mat-toolbar>
</div>
