<app-card-wrapper>
  <mat-expansion-panel>
    <mat-expansion-panel-header class="report-item">
      <mat-panel-title>
        {{data?.date | date: 'EEEE dd. MM. yyyy'}}
      </mat-panel-title>
      <mat-panel-description class="time-reported time-text">
        Vykázáno: {{hoursForThisDay}}h {{minutesForThisDay}}m
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <div class="one-report-item" *ngFor="let item of data.reportsForThisDay">

      <div fxLayout="column wrap" fxFlex="80" class="mt-3 mb-2">

        <div class="time" fxFlex="20">
          <div fxLayoutAlign="start center" class="ml-2 mb-2">

            <mat-icon>timer</mat-icon>
            <span
              class="item-hours-detail">{{item.dateFrom |date:'HH:mm'}}
              - {{item.dateTo |date: 'HH:mm'}}&nbsp; {{calclulateDiffForOneItem(item)}}</span>
          </div>
        </div>


        <div class="project mb-2 mt-2" fxFlex="20">
          <div fxLayoutAlign="start center" class="ml-2 ">
            <mat-icon>corporate_fare</mat-icon>
            {{item.projectInfo.name}}</div>
        </div>
        <div class="group" fxFlex="20">
          <div fxLayoutAlign="start center" class="ml-2 mb-2">
            <mat-icon>group</mat-icon>
            {{item.projectAction.name}}
          </div>
        </div>
        <div class="description" fxFlex="20">
          <div class="mt-2 mb-3">
            <div fxLayoutAlign="start center" class="ml-2">
              <mat-icon class="description-text">description</mat-icon>
              <div class="description-text"> {{item.description}}</div>
            </div>
          </div>
        </div>

      </div>

      <div class="action-buttons" fxLayout="column" fxFlex="20"
           fxLayoutAlign="start end">
        <button (click)="editTimeRecord(item)" mat-icon-button *ngIf="canEditOrDelete(item)">
          <mat-icon class="small-devices">create</mat-icon>
        </button>
        <button mat-icon-button color="warn" *ngIf="canEditOrDelete(item)" (click)="delete(item)">
          <mat-icon class="small-devices">delete</mat-icon>
        </button>
      </div>
    </div>

  </mat-expansion-panel>
</app-card-wrapper>
