<div class="table-responsive">
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Název</th>
      <td mat-cell *matCellDef="let row">{{row?.name}}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Popis</th>
      <td mat-cell *matCellDef="let row">{{row?.description}}</td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Upravit</th>
      <td mat-cell *matCellDef="let row">
        <button (click)="edit(row?.id)" mat-icon-button>
          <mat-icon>create</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Smazat</th>
      <td mat-cell *matCellDef="let row">
        <button (click)="remove(row?.id,row?.name)" mat-icon-button color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator
                 [length]="dataSource?.data?.length"
                 [pageIndex]="0"
                 [pageSize]="10"
                 [pageSizeOptions]="[10,25, 50]">
  </mat-paginator>
</div>
