import {Component, OnInit} from '@angular/core';
import {BaseEditPageComponent} from '../../core/base-classes/base-edit-page.component';
import {DtoOutAllProjectAction, DtoOutProjectInfo, ProjectActionService, ProjectService} from '../../generated';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-edit-project-page',
  templateUrl: './edit-project-page.component.html',
  styleUrls: ['./edit-project-page.component.scss']
})
export class EditProjectPageComponent extends BaseEditPageComponent<DtoOutProjectInfo> implements OnInit {
  public projectActions: DtoOutAllProjectAction[];

  constructor(private projectService: ProjectService, private title: Title, private activatedRoute: ActivatedRoute, private projectActionService: ProjectActionService) {
    super(title, 'Upravit projekt', activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getItem(id: number) {
    this.projectService.apiProjectGetProjectInfoIdGet(id).subscribe(data => {
      this.searchedData = data;
    });
    this.projectActionService.apiProjectActionGetAllProjectActionsForProjectIdProjectGet(id).subscribe(data => {
      this.projectActions = data;
    });
  }

}
