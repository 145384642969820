<div fxLayout="row" fxLayoutAlign="center center" >
  <div fxLayout="column" fxLayoutAlign="start start" >
    <img class="error" src="assets\images\error.png">
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" >

    <div class="mat-h1">Ooops! Něco se nepovedlo.</div>
    <span>Pokud si myslíte, že na tuto stránku máte mít přístup, klikněte na tlačítko níže.</span>
    <button [routerLink]="'/'" class="mt-4" mat-stroked-button color="primary">Zpět na homepage</button>
  </div>
</div>
