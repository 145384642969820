/**
 * Working time management
 * Working time management fro emploees of LarvaSystems
 *
 * OpenAPI spec version: 1
 * Contact: vojtech.stor@larvasystems.cz
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DtoInAddUserToProject } from '../model/dtoInAddUserToProject';
import { DtoInAddUserToProjectById } from '../model/dtoInAddUserToProjectById';
import { DtoInCreateProject } from '../model/dtoInCreateProject';
import { DtoInEditEmployeeProject } from '../model/dtoInEditEmployeeProject';
import { DtoInEditProject } from '../model/dtoInEditProject';
import { DtoOutAllProject } from '../model/dtoOutAllProject';
import { DtoOutAllProjectEmployeeByProject } from '../model/dtoOutAllProjectEmployeeByProject';
import { DtoOutProjectInfo } from '../model/dtoOutProjectInfo';
import { DtoOutProjectReportInfoForProjectManager } from '../model/dtoOutProjectReportInfoForProjectManager';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectAddUserToProjectByIdPost(body?: DtoInAddUserToProjectById, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectAddUserToProjectByIdPost(body?: DtoInAddUserToProjectById, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectAddUserToProjectByIdPost(body?: DtoInAddUserToProjectById, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectAddUserToProjectByIdPost(body?: DtoInAddUserToProjectById, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Project/AddUserToProjectById`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectAddUserToProjectPost(body?: DtoInAddUserToProject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Project/AddUserToProject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create project can start only ProjectManager
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectCreateProjectPost(body?: DtoInCreateProject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Project/CreateProject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * If you are ProjectManager you need to be assigned to project, and have role in the project as ProjectManager.  If you are admin you can delete all projects and dont need to be assigned in.
     * 
     * @param idProject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectDeleteProjectIdProjectDelete(idProject: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectDeleteProjectIdProjectDelete(idProject: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectDeleteProjectIdProjectDelete(idProject: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectDeleteProjectIdProjectDelete(idProject: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiProjectDeleteProjectIdProjectDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Project/DeleteProject/${encodeURIComponent(String(idProject))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectEditEmployeeProjectPatch(body?: DtoInEditEmployeeProject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectEditEmployeeProjectPatch(body?: DtoInEditEmployeeProject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectEditEmployeeProjectPatch(body?: DtoInEditEmployeeProject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectEditEmployeeProjectPatch(body?: DtoInEditEmployeeProject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/api/Project/EditEmployeeProject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit project can only someone who is assigned to project and have internal ProjectManager role
     * 
     * @param idProject 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectEditProjectIdProjectPatch(idProject: number, body?: DtoInEditProject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectEditProjectIdProjectPatch(idProject: number, body?: DtoInEditProject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectEditProjectIdProjectPatch(idProject: number, body?: DtoInEditProject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectEditProjectIdProjectPatch(idProject: number, body?: DtoInEditProject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiProjectEditProjectIdProjectPatch.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/api/Project/EditProject/${encodeURIComponent(String(idProject))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAllEmployeesByProjectId
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetAllEmployeesByProjectIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DtoOutAllProjectEmployeeByProject>>;
    public apiProjectGetAllEmployeesByProjectIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DtoOutAllProjectEmployeeByProject>>>;
    public apiProjectGetAllEmployeesByProjectIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DtoOutAllProjectEmployeeByProject>>>;
    public apiProjectGetAllEmployeesByProjectIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiProjectGetAllEmployeesByProjectIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DtoOutAllProjectEmployeeByProject>>('get',`${this.basePath}/api/Project/GetAllEmployeesByProject/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all projects of logged in user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetAllMyProjectsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DtoOutAllProject>>;
    public apiProjectGetAllMyProjectsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DtoOutAllProject>>>;
    public apiProjectGetAllMyProjectsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DtoOutAllProject>>>;
    public apiProjectGetAllMyProjectsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DtoOutAllProject>>('get',`${this.basePath}/api/Project/GetAllMyProjects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get info about money for employee
     * 
     * @param idEmployee 
     * @param month 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetEmployeeReportInfoForProjectManagerIdEmployeeGet(idEmployee: number, month?: number, year?: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutProjectReportInfoForProjectManager>;
    public apiProjectGetEmployeeReportInfoForProjectManagerIdEmployeeGet(idEmployee: number, month?: number, year?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutProjectReportInfoForProjectManager>>;
    public apiProjectGetEmployeeReportInfoForProjectManagerIdEmployeeGet(idEmployee: number, month?: number, year?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutProjectReportInfoForProjectManager>>;
    public apiProjectGetEmployeeReportInfoForProjectManagerIdEmployeeGet(idEmployee: number, month?: number, year?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idEmployee === null || idEmployee === undefined) {
            throw new Error('Required parameter idEmployee was null or undefined when calling apiProjectGetEmployeeReportInfoForProjectManagerIdEmployeeGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutProjectReportInfoForProjectManager>('get',`${this.basePath}/api/Project/GetEmployeeReportInfoForProjectManager/${encodeURIComponent(String(idEmployee))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get info about project if i am in the project.
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetProjectInfoIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutProjectInfo>;
    public apiProjectGetProjectInfoIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutProjectInfo>>;
    public apiProjectGetProjectInfoIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutProjectInfo>>;
    public apiProjectGetProjectInfoIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiProjectGetProjectInfoIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutProjectInfo>('get',`${this.basePath}/api/Project/GetProjectInfo/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param month 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectGetProjectReportInfoForProjectManagerIdGet(id: number, month?: number, year?: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutProjectReportInfoForProjectManager>;
    public apiProjectGetProjectReportInfoForProjectManagerIdGet(id: number, month?: number, year?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutProjectReportInfoForProjectManager>>;
    public apiProjectGetProjectReportInfoForProjectManagerIdGet(id: number, month?: number, year?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutProjectReportInfoForProjectManager>>;
    public apiProjectGetProjectReportInfoForProjectManagerIdGet(id: number, month?: number, year?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiProjectGetProjectReportInfoForProjectManagerIdGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutProjectReportInfoForProjectManager>('get',`${this.basePath}/api/Project/GetProjectReportInfoForProjectManager/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idProject 
     * @param idEmployee 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectRemoveUserFromProjectByProjectManagerIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectRemoveUserFromProjectByProjectManagerIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectRemoveUserFromProjectByProjectManagerIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectRemoveUserFromProjectByProjectManagerIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiProjectRemoveUserFromProjectByProjectManagerIdProjectIdEmployeeDelete.');
        }

        if (idEmployee === null || idEmployee === undefined) {
            throw new Error('Required parameter idEmployee was null or undefined when calling apiProjectRemoveUserFromProjectByProjectManagerIdProjectIdEmployeeDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Project/RemoveUserFromProjectByProjectManager/${encodeURIComponent(String(idProject))}/${encodeURIComponent(String(idEmployee))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idProject 
     * @param idEmployee 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProjectRemoveUserFromProjectIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiProjectRemoveUserFromProjectIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiProjectRemoveUserFromProjectIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiProjectRemoveUserFromProjectIdProjectIdEmployeeDelete(idProject: number, idEmployee: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiProjectRemoveUserFromProjectIdProjectIdEmployeeDelete.');
        }

        if (idEmployee === null || idEmployee === undefined) {
            throw new Error('Required parameter idEmployee was null or undefined when calling apiProjectRemoveUserFromProjectIdProjectIdEmployeeDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Project/RemoveUserFromProject/${encodeURIComponent(String(idProject))}/${encodeURIComponent(String(idEmployee))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
