<h2 mat-dialog-title>Vytvořit výkaz</h2>
<http-spinner>
  <form [formGroup]="form" (ngSubmit)="formSubmit()">
    <mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <div class="mr-3 sheet-text"><strong>Od</strong></div>
          <ngx-mat-timepicker formControlName="dateFromTime" [showSpinners]="false"></ngx-mat-timepicker>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <div class="mr-3 sheet-text"><strong>Do</strong></div>
          <ngx-mat-timepicker formControlName="dateToTime" [showSpinners]="false"></ngx-mat-timepicker>
        </div>
      </div>
      <mat-form-field class="datepicker-timesheet" validationMessage>
        <mat-label>Den</mat-label>
        <input (click)="picker.open()"  formControlName="date" name="date" matInput
               [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field validationMessage class="mr-3">
            <mat-select formControlName="idProject" name="idProject" placeholder="Vyberte projekt">
              <mat-option *ngFor="let item of myProjects" [value]="item?.id"
                          [matTooltip]="item?.description">{{item?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field validationMessage>
            <mat-select formControlName="idProjectAction" name="idProjectAction" placeholder="Vyberte roli">
              <mat-option *ngFor="let item of projectActions" [value]="item?.id"
                          [matTooltip]="item?.description">{{item?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" class="timesheet-description" validationMessage>
          <textarea
            matInput placeholder="Popis práce" formControlName="description" name="description"></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button [disabled]="btnDisabled" color="primary" class="save-button">Uložit
      </button>
      <button mat-raised-button color="accent" type="button" (click)="close()">Zavřít</button>
    </mat-dialog-actions>
  </form>
</http-spinner>
