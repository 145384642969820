<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title  class="mt-3">Všichni zaměstnanci</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="ml-4">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrování">
      </mat-form-field>

      <div class="table-responsive">
        <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Jméno</th>
            <td mat-cell *matCellDef="let row">{{row?.firstName}}</td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Přijmení</th>
            <td mat-cell *matCellDef="let row">{{row?.lastName}}</td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let row">{{row?.email}}</td>
          </ng-container>
          <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef>Otevřít</th>
            <td mat-cell *matCellDef="let row"><button (click)="view(row?.id)"  mat-icon-button><mat-icon>remove_red_eye</mat-icon></button></td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator
                       [length]="dataSource?.data?.length"
                       [pageIndex]="0"
                       [pageSize]="10"
                       [pageSizeOptions]="[10,25, 50]">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
