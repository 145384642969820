<div>
  <mat-card>
    <div class="ml-4 mat-h2">Výkazy zaměstnance</div>
    <mat-card-content>

      <div class="filtration ml-4">
        <div class="mat-small mb-2">Filtrování</div>
        <mat-divider></mat-divider>
        <mat-form-field class="mr-3">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrování">
        </mat-form-field>
        <mat-form-field class="mr-3">
          <mat-label>Měsíc</mat-label>
          <mat-select [(value)]="month">
            <mat-option [value]="null">--</mat-option>
            <mat-option *ngFor="let month of availableMonths" [value]="month.id">{{month.text}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mr-3">
          <mat-label>Rok</mat-label>
          <mat-select [(value)]="year">
            <mat-option [value]="null">--</mat-option>
            <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-stroked-button class="m-2 ml-4" (click)="exportToCsv()">
          <mat-icon>cloud_download</mat-icon>
          Stáhnout .csv
        </button>
      </div>
      <mat-divider></mat-divider>

      <div class="table-responsive">
        <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
          <ng-container matColumnDef="projectAction.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
            <td mat-cell *matCellDef="let row">{{row?.projectAction?.name}}</td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="dateFrom">Datum</th>
            <td mat-cell *matCellDef="let row">{{row?.dateFrom | date:'d. M. yyyy EEEE'}}</td>
          </ng-container>
          <ng-container matColumnDef="dateFrom">
            <th mat-header-cell *matHeaderCellDef>Od</th>
            <td mat-cell *matCellDef="let row">{{row?.dateFrom | date:'HH:mm'}}</td>
          </ng-container>
          <ng-container matColumnDef="dateTo">
            <th mat-header-cell *matHeaderCellDef>Do</th>
            <td mat-cell *matCellDef="let row">{{row?.dateTo | date : 'HH:mm'}}</td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Popis</th>
            <td mat-cell *matCellDef="let row">{{row?.description}}</td>
          </ng-container>
          <ng-container matColumnDef="projectInfo.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Projekt</th>
            <td mat-cell *matCellDef="let row">{{row?.projectInfo?.name}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator
                       [length]="dataSource?.data?.length"
                       [pageIndex]="0"
                       [pageSize]="10"
                       [pageSizeOptions]="[10,25, 50]">
        </mat-paginator>
      </div>
      <p><strong>Počet hodin:</strong> {{ calculateTime(moneyInfo?.hoursSpendOnProject)}}</p>
      <p><strong>Vydělaných peňez pro sebe:</strong> {{moneyInfo?.moneyToEmployees}}</p>
      <p><strong>Vydělaných peněz pro firmu:</strong> {{moneyInfo?.moneyToBusinees}}</p>
    </mat-card-content>
  </mat-card>
</div>
