import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {AuthService} from './core/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {EmployeeService} from './generated';
import {ApplicationInfoService} from './core/services/application-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, private applicationInfoService: ApplicationInfoService, private translateService: TranslateService, private authService: AuthService, private  employeeService: EmployeeService, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    registerLocaleData(localeCs, 'cs');
    this.translateService.setDefaultLang('cs');
    this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
      console.log('app authenticated', isAuthenticated);
      if (isAuthenticated === false) {
        window.localStorage.setItem('return_url', window.location.pathname);
        this.oidcSecurityService.authorize();
      } else {
        this.authService.loadRoles();
        const oldthis = this;
        this.employeeService.apiEmployeeAmIAlreadyEmployeeGet().subscribe(data => {
          if (data.isEmployee !== true) {
            oldthis.employeeService.apiEmployeeCreateMeEmployeeGet().subscribe(() => {

            });
          } else {
            this.applicationInfoService.loadApplication();
          }
        });

        if (window.localStorage.getItem('return_url')) {
          this.router.navigateByUrl(window.localStorage.getItem('return_url'));
          window.localStorage.removeItem('return_url');
        }
      }
    });
  }

}
