import {Component, Input, OnInit} from '@angular/core';
import {DtoOutEmployeeInfo} from '../../generated';

@Component({
  selector: 'app-employee-info-card',
  templateUrl: './employee-info-card.component.html',
  styleUrls: ['./employee-info-card.component.scss']
})
export class EmployeeInfoCardComponent implements OnInit {
  @Input()
  public employee: DtoOutEmployeeInfo;

  constructor() {
  }

  ngOnInit(): void {
  }

}
