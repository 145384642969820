<div>
  <mat-card class="project-card">
    <mat-card-header>
      <mat-card-title> {{edit === false ? 'Založení projektu' : 'Upravit '}}{{dataForEdit?.name}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="formSubmit()">
        <mat-form-field validationMessage class="ml-4 mr-3">
          <input formControlName="name" matInput placeholder="Jméno" name="name" required>
        </mat-form-field>
        <mat-form-field validationMessage>
          <input formControlName="description" matInput placeholder="Popis" name="description">
        </mat-form-field>


        <table mat-table [dataSource]="dataSource" formArrayName="projectActions">
          <!-- Row definitions -->
          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns;" [formGroupName]="i"></tr>

          <!-- Column definitions -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef > Název</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
              <mat-form-field validationMessage>
                <input matInput name="name" type="text" formControlName="name" placeholder="Název" required>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef > Popis</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
              <mat-form-field validationMessage>
                <input matInput name="description" type="text" formControlName="description" placeholder="Popis">
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Smazat</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button type="button" (click)="removeItem(row)" color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
        </table>
        <div class="ml-4">
          <button type="button" mat-raised-button (click)="addRow()" color="accent" class="mt-4 mr-3">Přidat projektovou
            roli
          </button>

          <button type="submit" mat-raised-button [disabled]="btnDisabled" color="primary">Uložit</button>
        </div>

      </form>
    </mat-card-content>
  </mat-card>
</div>
