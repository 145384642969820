<h2 class="ml-4">Projekty</h2>
<button class="ml-4" mat-stroked-button (click)="addEmployeeToProject()" color="primary">Přidat zaměstnance do projektu</button>
<div class="table-responsive">
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
      <td mat-cell *matCellDef="let row">{{row?.role}}</td>
    </ng-container>
    <ng-container matColumnDef="project.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Název</th>
      <td mat-cell *matCellDef="let row">{{row?.project?.name}}</td>
    </ng-container>
    <ng-container matColumnDef="project.description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Popis</th>
      <td mat-cell *matCellDef="let row">{{row?.project?.description}}</td>
    </ng-container>
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Náhled</th>
      <td mat-cell *matCellDef="let row"><button mat-icon-button (click)="viewProject(row?.idProject)" ><mat-icon>remove_red_eye</mat-icon></button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator
                 [length]="dataSource?.data?.length"
                 [pageIndex]="0"
                 [pageSize]="10"
                 [pageSizeOptions]="[10,25]">
  </mat-paginator>
</div>
