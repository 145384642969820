import {Component, Input, OnInit} from '@angular/core';
import {DtoOutAllMyReports, DtoOutTimeReportsForProjectEmployee, TimeService} from '../../generated';
import {NotificationsService} from '../../core/services/notifications.service';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {TimeReportChangeNotificationService} from '../../services/changeNotification/time-report-change-notification.service';
import {CreateEditTimeMainReportDialogComponent} from '../../dialogs/create-edit-time-main-report-dialog/create-edit-time-main-report-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-work-time-report-card',
  templateUrl: './work-time-report-card.component.html',
  styleUrls: ['./work-time-report-card.component.scss']
})
export class WorkTimeReportCardComponent implements OnInit {
  get data(): { date: Date; reportsForThisDay: Array<DtoOutAllMyReports> } {
    return this._data;
  }

  @Input()
  set data(value: { date: Date; reportsForThisDay: Array<DtoOutAllMyReports> }) {
    this._data = value;
    if (value) {
      this.calculateTimeForDay();
    }
  }


  private _data: { date: Date, reportsForThisDay: Array<DtoOutAllMyReports> };
  public hoursForThisDay = 0;
  public minutesForThisDay = 0;

  constructor(private timeService: TimeService, private notificationsService: NotificationsService, private confirmationDialogService: ConfirmationDialogService, private timeReportChangeNotificationService: TimeReportChangeNotificationService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  calculateTimeForDay() {
    this.hoursForThisDay = 0;
    this.minutesForThisDay = 0;
    this.data.reportsForThisDay.forEach(item => {
      this.hoursForThisDay += this.diffHours(new Date(item.dateFrom), new Date(item.dateTo)) / 60 / 60;
    });
    const before = this.hoursForThisDay;
    this.hoursForThisDay = Math.floor(this.hoursForThisDay);
    this.minutesForThisDay = Math.round((before - this.hoursForThisDay) * 60);
  }

  calclulateDiffForOneItem(item: DtoOutAllMyReports) {
    let hours = this.diffHours(new Date(item.dateFrom), new Date(item.dateTo)) / 60 / 60;
    const before = hours;
    hours = Math.floor(hours);
    const minutes = Math.round((before - hours) * 60);
    return hours + 'h ' + minutes + 'm';
  }

  diffHours(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(diff);
  }

  canEditOrDelete(data: DtoOutAllMyReports) {
    if (new Date(data.dateFrom).getMonth() === new Date().getMonth() && new Date(data.dateFrom).getFullYear() === new Date().getFullYear()) {
      return true;
    }
    return false;
  }

  delete(data: DtoOutAllMyReports) {
    this.confirmationDialogService.confirmDialog('Opravdu si přejete vymazat výkaz: ' + this.calclulateDiffForOneItem(data) + ' ' + data.description).subscribe((dialogResult) => {
      if (dialogResult) {
        const oldthis = this;
        this.timeService.apiTimeDeleteProjectReportIdProjectReportDelete(data.id).subscribe(result => {
          oldthis.notificationsService.ok('Výkaz byl úspěšně smazán');
          this.timeReportChangeNotificationService.timeReportChanges.next();
        }, error => {
          oldthis.notificationsService.error('Výkaz se nepodařilo smazat');
        });
      }
    });
  }

  editTimeRecord(item: DtoOutAllMyReports) {
    this.dialog.open(CreateEditTimeMainReportDialogComponent, {data: {edit: true, idProjectReport: item.id, projectReport: item}});
  }
}
