<div>
  <app-card-wrapper>
    <http-spinner>
      <div class="content ml-3 mr-3">
        <div fxLayout="row" fxLayoutAlign="start center" class="mt-4">
          <div class="mat-h1"> Přehled výkazů</div>
        </div>
        <hr>
        <div class=" mat-h4 mb-2 mt-3">Filtrování</div>
        <mat-form-field class="mr-4">
          <mat-label>Měsíc</mat-label>
          <mat-select [(value)]="month">
            <mat-option *ngFor="let month of availableMonths" [value]="month.id">{{month.text}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Rok</mat-label>
          <mat-select [(value)]="year">
            <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
        <hr>
        <div class="mat-h2">Celkový čas: {{hoursForThisMonth}} h  {{minutesForThisMonth}} m</div>
    </div>
      <button mat-fab class="floating-btn" (click)="createNewTimeReport()" [matTooltip]="'Vytvořit výkaz'">
        <mat-icon>add</mat-icon>
      </button>
      <mat-accordion multi>
        <div *ngFor="let item of groupedData">

          <app-work-time-report-card [data]="item"></app-work-time-report-card>
        </div>
      </mat-accordion>
    </http-spinner>
  </app-card-wrapper>
</div>
