import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ConfigRoutes} from '../../config/configRoutes';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ApplicationInfoService} from "../../services/application-info.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  get fullName(): Observable<string> {
    return this.oidcSecurityService.userData$.pipe(map(data => {
      if (Array.isArray(data?.name)) {
        return data?.name[0];
      }
      return data?.name;
    }));
  }

  get picture(): string {
    return this.applicationInfoService.pictureUrlLoggedUser;
  }

  public ConfigRoutes = ConfigRoutes;
  @Output()
  public menuToggleClick = new EventEmitter<void>();
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, public oidcSecurityService: OidcSecurityService, private applicationInfoService: ApplicationInfoService) {
  }

  loginClick() {
    this.oidcSecurityService.authorize();
  }

  ngOnInit() {

  }

  menuToggle() {
    this.menuToggleClick.emit();
  }

  logoutClick() {
    this.oidcSecurityService.logoff();
  }
}
