import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ProjectInfoService} from '../project-info-card/project-info.service';
import {

  DtoOutAllProjectReportsAdmin,
  DtoOutProjectReportInfoForProjectManager,
  DtoOutTimeReportsForProjectEmployee,
  ProjectService, TimeService
} from '../../generated';
import {DateHelper} from '../../core/date/date-helper';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ngxCsv} from 'ngx-csv';

@Component({
  selector: 'app-project-admin-report-card',
  templateUrl: './project-admin-report-card.component.html',
  styleUrls: ['./project-admin-report-card.component.scss']
})
export class ProjectAdminReportCardComponent implements OnInit {
  get allProjectReports(): Array<DtoOutAllProjectReportsAdmin> {
    return this._allProjectReports;
  }

  set allProjectReports(value: Array<DtoOutAllProjectReportsAdmin>) {
    this._allProjectReports = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.dataSource.data = value;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
        // Transform the filter by converting it to lowercase and removing whitespace.
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };
    }

    let valueY: number[] = [];
    this.projectReports.forEach(data => {
      let year = new Date(data.dateFrom).getFullYear();
      if (!valueY.find(x => x === year)) {
        valueY.push(year);
      }
    });
    this.availableYears = valueY;

    let valueM: { id: number, text: string }[] = [];
    this.projectReports.forEach(data => {
      let month = new Date(data.dateFrom).getMonth();
      if (!valueM.find(x => x.id === month)) {
        valueM.push({id: month, text: DateHelper.translateMonthToCzech(month)});
      }
    });
    this.availableMonths = valueM;
  }

  get projectReports(): Array<DtoOutTimeReportsForProjectEmployee> {
    return this._projectReports;
  }

  @Input()
  set projectReports(value: Array<DtoOutTimeReportsForProjectEmployee>) {
    this._projectReports = value;
  }

  private _allProjectReports: Array<DtoOutAllProjectReportsAdmin>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutAllProjectReportsAdmin>;
  displayedColumns = ['date', 'dateFrom', 'dateTo', 'employee.email', 'employee.firstName', 'employee.lastName', 'description', 'projectAction.name',];

  public availableMonths: { id: number, text: string }[] = [];

  public availableYears: number[] = [];

  get year(): number {
    return this._year;
  }

  set year(value: number) {
    this._year = value;
    if (this.month !== null && this.year) {
      this.load();
    } else if (this.month === null && this.year === null) {
      this.load();
    }
  }

  get month(): number {
    return this._month;
  }

  set month(value: number) {
    this._month = value;
    if (this.month !== null && this.year) {
      this.load();
    } else if (this.month === null && this.year === null) {
      this.load();
    }
  }

  @Input()
  public projectId: number;

  private _projectReports: Array<DtoOutTimeReportsForProjectEmployee>;
  private _year: number = null;
  private _month: number = null;

  public projectReportInfo: DtoOutProjectReportInfoForProjectManager;

  constructor(private projectInfoService: ProjectInfoService, private projectService: ProjectService, private timeService: TimeService) {
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  );

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  ngOnInit(): void {
    this.load();
    this.projectInfoService.projectInfoChanged.subscribe(() => {
      this.load();
    });
  }

  load() {
    if (this.month !== null) {
      this.projectService.apiProjectGetProjectReportInfoForProjectManagerIdGet(this.projectId, this.month + 1, this.year).subscribe(data => {
        this.projectReportInfo = data;
      });
      this.timeService.apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet(this.projectId, this.month + 1, this.year).subscribe(data => {
        this.allProjectReports = data;
      });
    } else {
      this.projectService.apiProjectGetProjectReportInfoForProjectManagerIdGet(this.projectId).subscribe(data => {
        this.projectReportInfo = data;
      });
      this.timeService.apiTimeGetProjectReportsForAdminIdProjectGet(this.projectId).subscribe(data => {
        this.allProjectReports = data;
      });
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportToCsv() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Výkazy projektu',
      useBom: false,
      noDownload: false,
      headers: []
    };
    let data = [];
    this.allProjectReports.forEach(item => {
      let obj = this.flatten(item);
      obj['hours'] = Math.abs(new Date(item.dateFrom).getTime() - new Date(item.dateTo).getTime()) / 36e5;
      data.push(obj);

    });
    if (data.length !== 0) {
      options.headers.push(Object.keys(data[0]));
    }
    new ngxCsv(data, 'Výkazy projektu', options);
  }

  traverseAndFlatten(currentNode, target, flattenedKey) {
    for (let key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        let newKey;
        if (flattenedKey === undefined) {
          newKey = key;
        } else {
          newKey = flattenedKey + '.' + key;
        }

        var value = currentNode[key];
        if (typeof value === 'object') {
          this.traverseAndFlatten(value, target, newKey);
        } else {
          target[newKey] = value;
        }
      }
    }
  }

  flatten(obj) {
    var flattenedObject = {};
    this.traverseAndFlatten(obj, flattenedObject, undefined);
    return flattenedObject;
  }

  calculateTime(time: number) {
    const before = time;
    let hours = Math.floor(time);
    let minutes = Math.round((before - hours)* 60) ;
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }
    return hours + 'h ' + minutes + ' m';
  }
}
