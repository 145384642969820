import {Component, OnInit} from '@angular/core';
import {EmployeeService} from '../../generated';
import {Router} from '@angular/router';
import {ConfigRoutes} from '../../core/config/configRoutes';
import {BasePageComponent} from '../../core/base-classes/base-page.component';
import {Title} from '@angular/platform-browser';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends BasePageComponent implements OnInit {

  constructor(private title: Title) {
    super(title, 'Domovská stránka');
  }

  ngOnInit(): void {

  }

}
