import {Component, OnInit} from '@angular/core';
import {BaseEditPageComponent} from '../../core/base-classes/base-edit-page.component';
import {DtoOutAllProjectEmployeeByProject, DtoOutProjectInfo, ProjectService} from '../../generated';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {ProjectInfoService} from '../../cards/project-info-card/project-info.service';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})
export class ProjectPageComponent extends BaseEditPageComponent<DtoOutProjectInfo> implements OnInit {
  public projectEmployees: Array<DtoOutAllProjectEmployeeByProject>;

  constructor(private title: Title, private activatedRoute: ActivatedRoute, private projectService: ProjectService, private projectInfoService: ProjectInfoService) {
    super(title, 'Projekt', activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  getItem(id: number) {
    this.projectInfoService.projectInfoChanged.subscribe(() => {
      const oldthis = this;
      this.projectService.apiProjectGetProjectInfoIdGet(id).subscribe(data => {
        oldthis.searchedData = data;
        oldthis.title.setTitle('Projekt | ' + oldthis.searchedData.name);
        if (data?.canEdit) {
          oldthis.projectService.apiProjectGetAllEmployeesByProjectIdGet(id).subscribe(datas => {
            oldthis.projectEmployees = datas;
          });
        }
      });
    });
    this.projectService.apiProjectGetProjectInfoIdGet(id).subscribe(data => {
      this.searchedData = data;
      this.title.setTitle('Projekt | ' + this.searchedData.name);
      if (data?.canEdit) {
        const oldthis=this;
        this.projectInfoService.projectEmployeesChanged.subscribe(() => {
          oldthis.projectService.apiProjectGetAllEmployeesByProjectIdGet(id).subscribe(datas => {
            oldthis.projectEmployees = datas;
          });
        });
        this.projectService.apiProjectGetAllEmployeesByProjectIdGet(id).subscribe(datas => {
          oldthis.projectEmployees = datas;
        });

      }
    });
  }

}
