import {BasePageComponent} from './base-page.component';
import {Title} from '@angular/platform-browser';
import {OnInit, Directive, Component, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseInfoPageComponent} from './base-info-page.component';

@Component({template: ''})
export abstract class BaseEditPageComponent<T> extends BaseInfoPageComponent implements OnInit {
  public searchedData: T;
  public searchedDataId: number;

  constructor(private titleSe: Title, @Inject(String) public titleString: string, private routesActive: ActivatedRoute) {
    super(titleSe, titleString, routesActive);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public abstract getItem(id: number) ;

}
