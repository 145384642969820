<mat-card class="m-3">
  <http-spinner>
    <app-card-wrapper>
      <app-project-info-card [projectInfo]="searchedData"></app-project-info-card>
    </app-card-wrapper>
  </http-spinner>
  <http-spinner *ngIf="searchedData?.canEdit===true">
    <app-card-wrapper>
      <app-project-employees-card [idProject]="+searchedDataId"
                                  [projectEmployees]="projectEmployees"></app-project-employees-card>
    </app-card-wrapper>
  </http-spinner>
</mat-card>
<http-spinner>
  <app-card-wrapper>
    <app-project-my-time-report-card
      [projectReports]="searchedData?.projectReports" [idProject]="+searchedDataId"></app-project-my-time-report-card>
  </app-card-wrapper>
</http-spinner>

<http-spinner *ngIf="searchedData?.canEdit===true">
  <app-card-wrapper>
    <app-project-admin-report-card [projectId]="+searchedDataId"
                                   [projectReports]="searchedData?.projectReports"></app-project-admin-report-card>
  </app-card-wrapper>
</http-spinner>

