import {Injectable} from '@angular/core';
import {Role} from '../role';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {UserService} from '../../generated';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userRoles: string[];
  private rolesLoaded: boolean = false;

  constructor(private oidcSecurityService: OidcSecurityService, private userService: UserService) {
  }


  hasRole(role: string): Observable<boolean> {
    return new Observable(observe => {
      const oldthis = this;
      this.getRoles().subscribe(data => {
        observe.next(data.filter(x => x === role).length !== 0);
        observe.complete();
      });
    });
  }

  hasRoleLive(role: string) {
    if (this.rolesLoaded === true) {
      return this.userRoles.filter(x => x === role).length !== 0;
    }
    return false;
  }

  getRoles(): Observable<string[]> {
    return new Observable(observe => {
      if (this.rolesLoaded) {

        observe.next(this.userRoles);
        observe.complete();

      } else {
        const oldthis = this;
        this.userService.apiUserGetAllMyRolesGet().subscribe(data => {
          oldthis.rolesLoaded = true;
          oldthis.userRoles = data.map(x => x.roleString);
          console.log('Roles Loaded ' + oldthis.userRoles);
          observe.next(oldthis.userRoles);
          observe.complete();
        });
      }
    });
  }

  loadRoles() {
    if (!this.rolesLoaded) {
      this.userService.apiUserGetAllMyRolesGet().subscribe(data => {
        this.rolesLoaded = true;
        this.userRoles = data.map(x => x.roleString);
      });
    }
  }
}
