import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DtoOutEmployeeCost, DtoOutProjectEmployee} from '../../../generated';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {CreateCostDialogComponent} from '../../../dialogs/create-cost-dialog/create-cost-dialog.component';

@Component({
  selector: 'app-employee-costs',
  templateUrl: './employee-costs.component.html',
  styleUrls: ['./employee-costs.component.scss']
})
export class EmployeeCostsComponent implements OnInit {
  @Input()
  public employeeId: number;

  get employeeCosts(): Array<DtoOutEmployeeCost> {
    return this._employeeCosts;
  }

  @Input()
  set employeeCosts(value: Array<DtoOutEmployeeCost>) {
    this._employeeCosts = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.data = value;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  private _employeeCosts: Array<DtoOutEmployeeCost>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutEmployeeCost>;
  displayedColumns = ['costForHim', 'costForBusiness', 'dateFrom', 'dateTo', 'edit'];

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  edit(id: number) {
    this.dialog.open(CreateCostDialogComponent, {data: {edit: true, employeeCost: this.employeeCosts.find(x => x.id === id)}});
  }

  create() {
    this.dialog.open(CreateCostDialogComponent, {data: {employeeId: this.employeeId}});
  }

}
