<div fxLayout="row wrap" fxLayoutAlign="start center">
  <h2 class="header-button-margin ml-4" matTooltip="Pokud chcete založit novou sazbui musíte ukončit předcházející"
      matTooltipPosition="above">Hodinová
    sazba</h2>

</div>
<div class="ml-4" fxLayout="row">
  <button mat-stroked-button color="primary" (click)="create()">
    Přidat hodinovou sazbu
  </button>
</div>
<div class="table-responsive">
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
    <ng-container matColumnDef="costForHim">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sazba pro zaměstnance</th>
      <td mat-cell *matCellDef="let row">{{row?.costForHim}}</td>
    </ng-container>
    <ng-container matColumnDef="costForBusiness">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sazba pro business</th>
      <td mat-cell *matCellDef="let row">{{row?.costForBusiness }}</td>
    </ng-container>
    <ng-container matColumnDef="dateFrom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data od</th>
      <td mat-cell *matCellDef="let row">{{row?.dateFrom |date}}</td>
    </ng-container>
    <ng-container matColumnDef="dateTo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data do</th>
      <td mat-cell *matCellDef="let row">{{row?.dateTo |date}}</td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Upravit</th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="edit(row?.id)">
          <mat-icon>create</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator
                 [length]="dataSource?.data?.length"
                 [pageIndex]="0"
                 [pageSize]="10"
                 [pageSizeOptions]="[10,25]">
  </mat-paginator>
</div>
