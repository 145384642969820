import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EmployeeService} from '../../generated';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInfoService {
  public pictureUrlLoggedUser = '';

  constructor(private employeeService: EmployeeService) {
  }

  loadApplication() {
    this.employeeService.apiEmployeeGetEmployeeInfoAboutMeGet().subscribe(data => {
      this.pictureUrlLoggedUser = data.pictureUrl;
    });
  }
}
