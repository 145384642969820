import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {AuthModule, LogLevel, OidcConfigService} from 'angular-auth-oidc-client';
import {ApiModule} from './generated';
import {ConfigurationService} from './core/configuration/configuration.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './core/TokenInterceptor';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {ProjectPageComponent} from './pages/project-page/project-page.component';
import {ProjectListPageComponent} from './pages/project-list-page/project-list-page.component';
import {MyWorkReportListPageComponent} from './pages/my-work-report-list-page/my-work-report-list-page.component';
import {HttpSpinnerModule} from 'http-spinner';
import {WorkTimeReportCardComponent} from './cards/work-time-report-card/work-time-report-card.component';
import {CreateProjectPageComponent} from './pages/create-project-page/create-project-page.component';
import {CreateEditProjectCardComponent} from './cards/create-edit-project-card/create-edit-project-card.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {EditProjectPageComponent} from './pages/edit-project-page/edit-project-page.component';
import {ProjectListTableCardComponent} from './cards/project-list-table-card/project-list-table-card.component';
import {EmployeesPageComponent} from './pages/employees-page/employees-page.component';
import {EmployeeListCardComponent} from './cards/employee-list-card/employee-list-card.component';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {EmployeePageComponent} from './pages/employee-page/employee-page.component';
import {EmployeeInfoCardComponent} from './cards/employee-info-card/employee-info-card.component';
import {EmployeeProjectsComponent} from './cards/employee-info-card/employee-projects/employee-projects.component';
import {EmployeeCostsComponent} from './cards/employee-info-card/employee-costs/employee-costs.component';
import {CreateCostDialogComponent} from './dialogs/create-cost-dialog/create-cost-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
import {ProjectInfoCardComponent} from './cards/project-info-card/project-info-card.component';
import {ProjectEmployeesCardComponent} from './cards/project-employees-card/project-employees-card.component';
import {ProjectAdminReportCardComponent} from './cards/project-admin-report-card/project-admin-report-card.component';
import {ProjectMyTimeReportCardComponent} from './cards/project-my-time-report-card/project-my-time-report-card.component';
import {ProjectInfoActionsTableComponent} from './cards/project-info-card/project-info-actions-table/project-info-actions-table.component';
import {EditProjectRoleDialogComponent} from './dialogs/edit-project-role-dialog/edit-project-role-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {AddEmployeeToProjectDialogComponent} from './dialogs/add-employee-to-project-dialog/add-employee-to-project-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AddProjectToEmployeeDialogComponent} from './dialogs/add-project-to-employee-dialog/add-project-to-employee-dialog.component';
import {EmployeeTimeReportsCardComponent} from './cards/employee-time-reports-card/employee-time-reports-card.component';
import {getCzechPaginatorIntl} from './core/material/myMatPaginator';
import {MatExpansionModule} from '@angular/material/expansion';
import {CreateEditTimeMainReportDialogComponent} from './dialogs/create-edit-time-main-report-dialog/create-edit-time-main-report-dialog.component';
import {TranslateModule} from '@ngx-translate/core';


export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: 'https://oidc.larvasystems.cz',
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      clientId: 'wtmClientJs',
      scope: 'openid profile UsersApi WorkingTimeManagementApi offline_access',
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      logLevel: LogLevel.Error,
    });
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ProjectPageComponent,
    ProjectListPageComponent,
    MyWorkReportListPageComponent,
    WorkTimeReportCardComponent,
    CreateProjectPageComponent,
    CreateEditProjectCardComponent,
    EditProjectPageComponent,
    ProjectListTableCardComponent,
    EmployeesPageComponent,
    EmployeeListCardComponent,
    EmployeePageComponent,
    EmployeeInfoCardComponent,
    EmployeeProjectsComponent,
    EmployeeCostsComponent,
    CreateCostDialogComponent,
    ProjectInfoCardComponent,
    ProjectEmployeesCardComponent,
    ProjectAdminReportCardComponent,
    ProjectMyTimeReportCardComponent,
    ProjectInfoActionsTableComponent,
    EditProjectRoleDialogComponent,
    AddEmployeeToProjectDialogComponent,
    AddProjectToEmployeeDialogComponent,
    EmployeeTimeReportsCardComponent,
    CreateEditTimeMainReportDialogComponent
  ],
  imports: [
    BrowserModule,
    AuthModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    ApiModule.forRoot(ConfigurationService.getConfigForGenerated),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    HttpSpinnerModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    FlexModule,
    MatSelectModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxMatTimepickerModule,
    TranslateModule.forRoot()
  ],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {provide: MatPaginatorIntl, useValue: getCzechPaginatorIntl()},
    {
      provide: LOCALE_ID, useValue: 'cs'
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
