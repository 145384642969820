import {NgModule, ModuleWithProviders, SkipSelf, Optional} from '@angular/core';
import {Configuration} from './configuration';
import {HttpClient} from '@angular/common/http';


import {CostService} from './api/cost.service';
import {EmployeeService} from './api/employee.service';
import {ManagementService} from './api/management.service';
import {ProjectService} from './api/project.service';
import {ProjectActionService} from './api/projectAction.service';
import {TimeService} from './api/time.service';
import {UserService} from './api/user.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    CostService,
    EmployeeService,
    ManagementService,
    ProjectService,
    ProjectActionService,
    TimeService,
    UserService]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule,
              @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
