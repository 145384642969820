import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {ConfigRoutes} from '../config/configRoutes';
import {MyWorkReportListPageComponent} from '../../pages/my-work-report-list-page/my-work-report-list-page.component';
import {AuthGuardService} from '../services/guard/auth-guard.service';
import {AuthService} from '../services/auth.service';
import {map} from 'rxjs/operators';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hasAccessToView]'
})
export class AuthorizationDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef, private authService: AuthService, private oidcSecurityService: OidcSecurityService
  ) {
  }

  @Input() set hasAccessToView(allowedRoles: string[]) {
    this.authService.getRoles().subscribe(myRoles => {
      if (allowedRoles.some(r => {
        return myRoles.filter(x => x === r).length !== 0;
      })) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

}
