<form [formGroup]="form" (ngSubmit)="formSubmit()">
  <h1 mat-dialog-title>Editace role</h1>
  <div>
    <mat-form-field validationMessage>
      <input
             matInput placeholder="Název" formControlName="name" name="name">
    </mat-form-field>
    <mat-form-field validationMessage>
      <input
             matInput placeholder="Popis" formControlName="description" name="description">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="btnDisabled" color="primary">Uložit
    </button>
    <button mat-raised-button color="accent" type="button" (click)="close()">Zavřít</button>
  </div>
</form>
