export * from './cost.service';
import { CostService } from './cost.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './management.service';
import { ManagementService } from './management.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './projectAction.service';
import { ProjectActionService } from './projectAction.service';
export * from './time.service';
import { TimeService } from './time.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [CostService, EmployeeService, ManagementService, ProjectService, ProjectActionService, TimeService, UserService];
