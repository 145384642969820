<form [formGroup]="form" (ngSubmit)="formSubmit()">
  <div class="mat-h3" mat-dialog-title> Přidání zaměstnance do projektu</div>
  <div fxLayout="row wrap">
    <mat-form-field validationMessage>
      <mat-label>Projekt</mat-label>
      <mat-select formControlName="idProject" name="idProject">
        <mat-option *ngFor="let project of availableProjects" [matTooltip]="project?.description"
                    matTooltipPosition="above" [value]="project?.id">
          {{project?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row wrap" class="mt-4 mb-3">
  <mat-checkbox formControlName="canEdit">Může upravovat projekt?</mat-checkbox>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="btnDisabled" color="primary">Uložit
    </button>
    <button mat-raised-button color="accent" type="button" (click)="close()">Zavřít</button>
  </div>
</form>
