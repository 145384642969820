<http-spinner>
  <app-card-wrapper>
    <app-employee-info-card [employee]="employee"></app-employee-info-card>
  </app-card-wrapper>
</http-spinner>

<http-spinner>
  <app-card-wrapper>
    <app-employee-time-reports-card [employeeName]="employee?.firstName + ' '+ employee?.lastName"
                                    [employeeId]="employee?.id"></app-employee-time-reports-card>
  </app-card-wrapper>
</http-spinner>
