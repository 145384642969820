import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  DtoInEditProjectAction,
  DtoOutProjectAction,
  ProjectActionService
} from '../../generated';
import {BaseEditPageComponent} from '../../core/base-classes/base-edit-page.component';
import {BaseEditCreateFormComponent} from '../../core/base-classes/base-edit-create-form.component';
import {BaseEditFormComponent} from '../../core/base-classes/base-edit-form.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../core/services/notifications.service';
import {ProjectInfoService} from '../../cards/project-info-card/project-info.service';

@Component({
  selector: 'app-edit-project-role-dialog',
  templateUrl: './edit-project-role-dialog.component.html',
  styleUrls: ['./edit-project-role-dialog.component.scss']
})
export class EditProjectRoleDialogComponent extends BaseEditFormComponent<DtoInEditProjectAction, DtoOutProjectAction> implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditProjectRoleDialogComponent>, private projectInfoService: ProjectInfoService,
              @Inject(MAT_DIALOG_DATA) public data: { projectAction: DtoOutProjectAction }, private projectActionService: ProjectActionService, private notificationsService: NotificationsService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  createForm() {
    this.form = new FormGroup({
      'id': new FormControl(this.data.projectAction.id),
      'name': new FormControl(this.data.projectAction.name, [Validators.required, Validators.minLength(2), Validators.maxLength(60)]),
      'description': new FormControl(this.data.projectAction.description, [Validators.minLength(2), Validators.maxLength(150)]),
    });
  }

  getDataEdit(): DtoInEditProjectAction {
    let result = this.getFormValue() as DtoInEditProjectAction;
    return result;
  }

  close() {
    this.dialogRef.close();
  }

  requestInFormSubmit() {
    this.projectActionService.apiProjectActionUpdateProjectActionPatch(this.getDataEdit()).subscribe(data => {
      this.notificationsService.ok('Úprava role proběhla úspěšně');
      this.btnDisabled = false;
      this.projectInfoService.projectInfoChanged.next();
      this.close();
    }, error => {
      this.notificationsService.error('Úprava role se nezdařila');
      this.btnDisabled = false;
    });
  }

}
