<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Zaměstnanci
    </mat-panel-title>
    <mat-panel-description>
      Tento panel obsahuje přehled zaměstnanců v projektu.
    </mat-panel-description>
  </mat-expansion-panel-header>
  <button mat-stroked-button (click)="addEmployee()" color="primary">
    Přidat zaměstnance do projektu
  </button>
  <div class="table-responsive">
    <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let row">{{row?.role}}</td>
      </ng-container>
      <ng-container matColumnDef="employee.firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Jméno</th>
        <td mat-cell *matCellDef="let row">{{row?.employee?.firstName}}</td>
      </ng-container>
      <ng-container matColumnDef="employee.lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Příjmení</th>
        <td mat-cell *matCellDef="let row">{{row?.employee?.lastName}}</td>
      </ng-container>
      <ng-container matColumnDef="employee.email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{row?.employee?.email}}</td>
      </ng-container>

      <ng-container matColumnDef="canEdit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Může upravovat</th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox *ngIf="(isNotMe(row) | async)" (change)="roleChanged($event.checked,row?.id)"
                        [checked]="row?.role ==='ProjectManager'?true:false"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Smazat</th>
        <td mat-cell *matCellDef="let row">
          <button *ngIf="canRemove(row?.role)" mat-icon-button type="button"
                  (click)="remove(row?.employee?.id,row?.employee?.firstName, row?.employee?.lastName)"
                  color="warn">
            <mat-icon>delete</mat-icon>
          </button>
          <span *ngIf="!canRemove(row?.role)">
              <span *ngIf="(isNotMe(row) | async)">
              <button *hasAccessToView="['WorkingTimeManagementAdmin']"
                      (click)="removeAsAdmin(row?.employee?.id,row?.employee?.firstName, row?.employee?.lastName)"
                      mat-icon-button
                      color="warn">
              <mat-icon>delete</mat-icon>
            </button>
                </span>
            </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator
                   [length]="dataSource?.data?.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[10,25]">
    </mat-paginator>
  </div>
</mat-expansion-panel>
