import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DtoInEditEmployeeProject, DtoOutAllProjectEmployeeByProject, DtoOutProjectEmployee, ProjectService} from '../../generated';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {map} from 'rxjs/operators';
import {Role} from '../../core/role';
import {MatDialog} from '@angular/material/dialog';
import {AddEmployeeToProjectDialogComponent} from '../../dialogs/add-employee-to-project-dialog/add-employee-to-project-dialog.component';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {NotificationsService} from '../../core/services/notifications.service';
import {ProjectInfoService} from '../project-info-card/project-info.service';
import {Observable} from 'rxjs';
import {AuthService} from '../../core/services/auth.service';

@Component({
  selector: 'app-project-employees-card',
  templateUrl: './project-employees-card.component.html',
  styleUrls: ['./project-employees-card.component.scss']
})
export class ProjectEmployeesCardComponent implements OnInit {
  get projectEmployees(): Array<DtoOutAllProjectEmployeeByProject> {
    return this._projectEmployees;
  }

  @Input()
  public idProject: number;

  @Input()
  set projectEmployees(value: Array<DtoOutAllProjectEmployeeByProject>) {
    this._projectEmployees = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.data = value;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.setColumns();
    }
  }

  private _projectEmployees: Array<DtoOutAllProjectEmployeeByProject>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutAllProjectEmployeeByProject>;
  displayedColumns = ['employee.firstName', 'employee.lastName', 'employee.email', 'role', 'delete'];


  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  );


  constructor(private oidcSecurityService: OidcSecurityService, private matDialog: MatDialog, private confirmationDialogService: ConfirmationDialogService,
              private notificationsService: NotificationsService, private projectService: ProjectService, private projectInfoService: ProjectInfoService, private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  setColumns() {
    this.authService.hasRole(Role.WorkingTimeManagementAdmin.toString()).subscribe(data => {
      if (data) {
        this.displayedColumns = ['employee.firstName', 'employee.lastName', 'employee.email', 'role', 'canEdit', 'delete'];
      } else {
        this.displayedColumns = ['employee.firstName', 'employee.lastName', 'employee.email', 'role', 'delete'];
      }
    });
  }

  roleChanged(canEdit: boolean, id: number) {
    let body: DtoInEditEmployeeProject = {id, canEdit};
    this.projectService.apiProjectEditEmployeeProjectPatch(body).subscribe(data => {
      this.projectInfoService.projectEmployeesChanged.next();
    }, error => {

    });
  }

  addEmployee() {
    this.matDialog.open(AddEmployeeToProjectDialogComponent, {data: {idProject: this.idProject}});
  }

  canRemove(role: string) {
    if (role === Role.Employee) {
      return true;
    }
    return false;
  }

  isNotMe(data: DtoOutAllProjectEmployeeByProject): Observable<boolean> {
    return new Observable((obserever) => {
      this.oidcSecurityService.userData$.subscribe(val => {
        if (val) {
          if (data.employee.email === val?.preferred_username) {
            obserever.next(false);
            obserever.complete();
          }
          obserever.next(true);
          obserever.complete();
        }else {
          this.oidcSecurityService.authorize();
        }
      });
    });
  }

  remove(id: number, firstName: string, lastName: string) {
    this.confirmationDialogService.confirmDialog('Opravdu chcete odstranit zaměstnance ' + firstName + ' ' + lastName + ' z projektu?').subscribe(data => {
      if (data) {
        const oldthis = this;
        this.projectService.apiProjectRemoveUserFromProjectIdProjectIdEmployeeDelete(this.idProject, id).subscribe(value => {
          oldthis.notificationsService.ok('Zaměstnanec byl úspěšně odstraněn z projektu');
          this.projectInfoService.projectEmployeesChanged.next();
        }, error => {
          oldthis.notificationsService.ok('Zaměstnance se nepodařilo odstranit z projektu');
        });
      }
    });
  }

  removeAsAdmin(id: number, firstName: string, lastName: string) {
    this.confirmationDialogService.confirmDialog('Opravdu chcete odstranit zaměstnance ' + firstName + ' ' + lastName + ' z projektu?').subscribe(data => {
      if (data) {
        const oldthis = this;
        this.projectService.apiProjectRemoveUserFromProjectByProjectManagerIdProjectIdEmployeeDelete(this.idProject, id).subscribe(value => {
          oldthis.notificationsService.ok('Zaměstnanec byl úspěšně odstraněn z projektu');
          this.projectInfoService.projectEmployeesChanged.next();
        }, error => {
          oldthis.notificationsService.ok('Zaměstnance se nepodařilo odstranit z projektu');
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
