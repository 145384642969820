export class ConfigRoutes {
  public static readonly home = '/';
  public static readonly projectPage = 'project';
  public static readonly myProjecstPage = 'projects';
  public static readonly myWorkReportListPage = 'myReports';
  public static readonly createProjectPage = 'createProject';
  public static readonly editProjectPage = 'editProject';
  public static readonly employees = 'employees';
  public static readonly employee = 'employee';
  public static readonly unauthorizedRoute = 'unauthorized';


}
