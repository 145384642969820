import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DtoOutProjectAction, DtoOutTimeReportsForProjectEmployee, TimeService} from '../../generated';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {NotificationsService} from '../../core/services/notifications.service';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {ProjectInfoService} from '../project-info-card/project-info.service';
import {DateHelper} from '../../core/date/date-helper';

@Component({
  selector: 'app-project-my-time-report-card',
  templateUrl: './project-my-time-report-card.component.html',
  styleUrls: ['./project-my-time-report-card.component.scss']
})
export class ProjectMyTimeReportCardComponent implements OnInit {
  get selectedYear(): number {
    return this._selectedYear;
  }

  set selectedYear(value: number) {
    this._selectedYear = value;
    this.applyFilter();
  }

  get selectedMonth(): number {
    return this._selectedMonth;
  }

  set selectedMonth(value: number) {
    this._selectedMonth = value;
    this.applyFilter();
  }

  @Input()
  public idProject: number;

  get projectReports(): Array<DtoOutTimeReportsForProjectEmployee> {
    return this._projectReports;
  }

  public availableMonths: { id: number, text: string }[] = [];

  public availableYears: number[] = [];
  private _selectedMonth: number;
  private _selectedYear: number;

  @Input()
  set projectReports(value: Array<DtoOutTimeReportsForProjectEmployee>) {
    this._projectReports = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      this.dataSource.data = value;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (data, filter) => {
        if (this.selectedYear && this.selectedMonth) {
          if (new Date(data.dateFrom).getMonth() === this._selectedMonth && new Date(data.dateFrom).getFullYear() === this._selectedYear) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      };

      let valueY: number[] = [];
      this.projectReports.forEach(data => {
        let year = new Date(data.dateFrom).getFullYear();
        if (!valueY.find(x => x === year)) {
          valueY.push(year);
        }
      });
      this.availableYears = valueY;

      let valueM: { id: number, text: string }[] = [];
      this.projectReports.forEach(data => {
        let month = new Date(data.dateFrom).getMonth();
        if (!valueM.find(x => x.id === month)) {
          valueM.push({id: month, text: DateHelper.translateMonthToCzech(month)});
        }
      });
      this.availableMonths = valueM;
    }
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutTimeReportsForProjectEmployee>;
  displayedColumns = ['date', 'dateFrom', 'dateTo', 'description', 'projectAction.name',  'delete'];

  private _projectReports: Array<DtoOutTimeReportsForProjectEmployee>;

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
  );

  constructor(private notificationsService: NotificationsService, private confirmationDialogService: ConfirmationDialogService,
              private timeService: TimeService, private dialog: MatDialog, private projectInfoService: ProjectInfoService) {
  }

  ngOnInit(): void {
  }


  remove(id: number) {
    let actualReport = this.projectReports.find(x => x.id === id);
    this.confirmationDialogService.confirmDialog('Opravdu chcete smazat výkaz na roli ' + actualReport.projectAction.name + ' s popisem ' + actualReport.description).subscribe(data => {
      if (data === true) {
        const oldthis = this;
        this.timeService.apiTimeDeleteProjectReportIdProjectReportDelete(id).subscribe(() => {
          oldthis.notificationsService.ok('Výkaz byl úspěšně smazán');
          oldthis.projectInfoService.projectInfoChanged.next();
        }, error => {
          oldthis.notificationsService.ok('Výkaz se nepodařilo smazat');
        });
      }

    });
  }

  canEdit(data: DtoOutTimeReportsForProjectEmployee) {
    if (new Date(data.dateFrom).getMonth() === new Date().getMonth() && new Date(data.dateFrom).getFullYear() === new Date().getFullYear()) {
      return true;
    }
    return false;
  }

  applyFilter() {
    this.dataSource.filter = '' + Math.random();
  }
}
