import {Component, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {NavItem} from './menu-list-item/NavItem';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatSidenavContainer} from '@angular/material/sidenav';
import {ConfigRoutes} from '../../config/configRoutes';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {EmployeeService} from '../../../generated';
import {ApplicationInfoService} from '../../services/application-info.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {


  public ConfigRoutes = ConfigRoutes;
  @ViewChild('navcontainer', {static: false}) navcontainer: MatSidenavContainer;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches)
    );

  navItems: NavItem[] = [
    {
      displayName: 'Výkazy',
      iconName: 'access_time',
      role: ['Employee'],
      route: ConfigRoutes.myWorkReportListPage
    },
    {
      displayName: 'Projekty',
      iconName: 'business_center',
      role: ['Employee'],
      children: [
        {
          displayName: 'Moje projekty',
          iconName: 'business',
          route: ConfigRoutes.myProjecstPage,
          role: ['Employee']
        },
        {
          displayName: 'Založte projekt',
          iconName: 'add_business',
          route: ConfigRoutes.createProjectPage,
          role: ['ProjectManager']
        }
      ]
    },
    {
      displayName: 'Zaměstnanci',
      iconName: 'people',
      route: ConfigRoutes.employees,
      role: ['WorkingTimeManagementAdmin'],
    }
  ];

  constructor(private breakpointObserver: BreakpointObserver, public oidcSecurityService: OidcSecurityService) {
  }

  onToggleMenu() {
    setTimeout(() => this.navcontainer.updateContentMargins());
  }

  ngOnInit(): void {
  }
}
