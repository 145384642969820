/**
 * Working time management
 * Working time management fro emploees of LarvaSystems
 *
 * OpenAPI spec version: 1
 * Contact: vojtech.stor@larvasystems.cz
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DtoInCreateProjectReport } from '../model/dtoInCreateProjectReport';
import { DtoInUpdateProjectReport } from '../model/dtoInUpdateProjectReport';
import { DtoOutAllMyReports } from '../model/dtoOutAllMyReports';
import { DtoOutAllProjectReports } from '../model/dtoOutAllProjectReports';
import { DtoOutAllProjectReportsAdmin } from '../model/dtoOutAllProjectReportsAdmin';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TimeService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * CreateProjectReport for project
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeCreateProjectReportPost(body?: DtoInCreateProjectReport, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTimeCreateProjectReportPost(body?: DtoInCreateProjectReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTimeCreateProjectReportPost(body?: DtoInCreateProjectReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTimeCreateProjectReportPost(body?: DtoInCreateProjectReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Time/CreateProjectReport`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete project report
     * 
     * @param idProjectReport 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeDeleteProjectReportIdProjectReportDelete(idProjectReport: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTimeDeleteProjectReportIdProjectReportDelete(idProjectReport: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTimeDeleteProjectReportIdProjectReportDelete(idProjectReport: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTimeDeleteProjectReportIdProjectReportDelete(idProjectReport: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProjectReport === null || idProjectReport === undefined) {
            throw new Error('Required parameter idProjectReport was null or undefined when calling apiTimeDeleteProjectReportIdProjectReportDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Time/DeleteProjectReport/${encodeURIComponent(String(idProjectReport))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMyProjectReports by month and logged user
     * 
     * @param idProject 
     * @param month 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeGetMyProjectReportsMonthIdProjectGet(idProject: number, month?: number, year?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DtoOutAllProjectReports>>;
    public apiTimeGetMyProjectReportsMonthIdProjectGet(idProject: number, month?: number, year?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DtoOutAllProjectReports>>>;
    public apiTimeGetMyProjectReportsMonthIdProjectGet(idProject: number, month?: number, year?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DtoOutAllProjectReports>>>;
    public apiTimeGetMyProjectReportsMonthIdProjectGet(idProject: number, month?: number, year?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiTimeGetMyProjectReportsMonthIdProjectGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DtoOutAllProjectReports>>('get',`${this.basePath}/api/Time/GetMyProjectReportsMonth/${encodeURIComponent(String(idProject))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all my reports
     * 
     * @param month 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeGetMyReportsForMonthGet(month?: number, year?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DtoOutAllMyReports>>;
    public apiTimeGetMyReportsForMonthGet(month?: number, year?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DtoOutAllMyReports>>>;
    public apiTimeGetMyReportsForMonthGet(month?: number, year?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DtoOutAllMyReports>>>;
    public apiTimeGetMyReportsForMonthGet(month?: number, year?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DtoOutAllMyReports>>('get',`${this.basePath}/api/Time/GetMyReportsForMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GEtProjectReport for info and edit purposes
     * 
     * @param idProjectReport 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeGetProjectReportIdProjectReportGet(idProjectReport: number, observe?: 'body', reportProgress?: boolean): Observable<DtoOutAllProjectReports>;
    public apiTimeGetProjectReportIdProjectReportGet(idProjectReport: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DtoOutAllProjectReports>>;
    public apiTimeGetProjectReportIdProjectReportGet(idProjectReport: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DtoOutAllProjectReports>>;
    public apiTimeGetProjectReportIdProjectReportGet(idProjectReport: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProjectReport === null || idProjectReport === undefined) {
            throw new Error('Required parameter idProjectReport was null or undefined when calling apiTimeGetProjectReportIdProjectReportGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DtoOutAllProjectReports>('get',`${this.basePath}/api/Time/GetProjectReport/${encodeURIComponent(String(idProjectReport))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idProject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeGetProjectReportsForAdminIdProjectGet(idProject: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DtoOutAllProjectReportsAdmin>>;
    public apiTimeGetProjectReportsForAdminIdProjectGet(idProject: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DtoOutAllProjectReportsAdmin>>>;
    public apiTimeGetProjectReportsForAdminIdProjectGet(idProject: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DtoOutAllProjectReportsAdmin>>>;
    public apiTimeGetProjectReportsForAdminIdProjectGet(idProject: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiTimeGetProjectReportsForAdminIdProjectGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DtoOutAllProjectReportsAdmin>>('get',`${this.basePath}/api/Time/GetProjectReportsForAdmin/${encodeURIComponent(String(idProject))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetProjectReports by month for admin report to project
     * 
     * @param idProject 
     * @param month 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet(idProject: number, month: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DtoOutAllProjectReportsAdmin>>;
    public apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet(idProject: number, month: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DtoOutAllProjectReportsAdmin>>>;
    public apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet(idProject: number, month: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DtoOutAllProjectReportsAdmin>>>;
    public apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet(idProject: number, month: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProject === null || idProject === undefined) {
            throw new Error('Required parameter idProject was null or undefined when calling apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet.');
        }

        if (month === null || month === undefined) {
            throw new Error('Required parameter month was null or undefined when calling apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling apiTimeGetProjectReportsMonthForAdminIdProjectMonthYearGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DtoOutAllProjectReportsAdmin>>('get',`${this.basePath}/api/Time/GetProjectReportsMonthForAdmin/${encodeURIComponent(String(idProject))}/${encodeURIComponent(String(month))}/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get employee reports for month
     * 
     * @param month 
     * @param year 
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeGetReportsByEmployeeForMonthGet(month?: number, year?: number, employeeId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DtoOutAllMyReports>>;
    public apiTimeGetReportsByEmployeeForMonthGet(month?: number, year?: number, employeeId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DtoOutAllMyReports>>>;
    public apiTimeGetReportsByEmployeeForMonthGet(month?: number, year?: number, employeeId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DtoOutAllMyReports>>>;
    public apiTimeGetReportsByEmployeeForMonthGet(month?: number, year?: number, employeeId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (employeeId !== undefined && employeeId !== null) {
            queryParameters = queryParameters.set('employeeId', <any>employeeId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DtoOutAllMyReports>>('get',`${this.basePath}/api/Time/GetReportsByEmployeeForMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * edit Project report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimeUpdateProjectReportPatch(body?: DtoInUpdateProjectReport, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTimeUpdateProjectReportPatch(body?: DtoInUpdateProjectReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTimeUpdateProjectReportPatch(body?: DtoInUpdateProjectReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTimeUpdateProjectReportPatch(body?: DtoInUpdateProjectReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/api/Time/UpdateProjectReport`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
