<mat-card>
  <mat-card-header>
    <mat-card-title class="mt-3 mb-3">
      Moje výkazy do projektu
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="filtration ml-4">
      <div class="mat-small mb-2">Filtrování</div>
      <mat-divider></mat-divider>

      <mat-form-field class="mr-3">
        <mat-label>Měsíc</mat-label>
        <mat-select [(value)]="selectedMonth">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let month of availableMonths" [value]="month.id">{{month.text}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Rok</mat-label>
        <mat-select [(value)]="selectedYear">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-divider></mat-divider>
    </div>
    <div class="table-responsive">
      <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
        <ng-container matColumnDef="projectAction.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell *matCellDef="let row">{{row?.projectAction?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Popis</th>
          <td mat-cell *matCellDef="let row">{{row?.description}}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="dateFrom">Datum</th>
          <td mat-cell *matCellDef="let row">{{row?.dateFrom | date:'d. M. yyyy EEEE'}}</td>
        </ng-container>
        <ng-container matColumnDef="dateFrom">
          <th mat-header-cell *matHeaderCellDef>Od</th>
          <td mat-cell *matCellDef="let row">{{row?.dateFrom | date:'HH:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="dateTo">
          <th mat-header-cell *matHeaderCellDef>Do</th>
          <td mat-cell *matCellDef="let row">{{row?.dateTo | date : 'HH:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>Smazat</th>
          <td mat-cell *matCellDef="let row">
            <button *ngIf="canEdit(row)" (click)="remove(row?.id)" mat-icon-button color="warn">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator
                     [length]="dataSource?.data?.length"
                     [pageIndex]="0"
                     [pageSize]="10"
                     [pageSizeOptions]="[10,25, 50]">
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
