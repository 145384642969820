export class DateHelper {
  public static translateMonthToCzech(month: number): string {
    if (month === 0) {
      return 'Leden';
    } else if (month === 1) {
      return 'Únor';
    } else if (month === 2) {
      return 'Březen';
    } else if (month === 3) {
      return 'Duben';
    } else if (month === 4) {
      return 'Květen';
    } else if (month === 5) {
      return 'Červen';
    } else if (month === 6) {
      return 'Červenec';
    } else if (month === 7) {
      return 'Srpen';
    } else if (month === 8) {
      return 'Září';
    } else if (month === 9) {
      return 'Říjen';
    } else if (month === 10) {
      return 'Listopad';
    } else if (month === 11) {
      return 'Prosinec';
    }
    return '';
  }
}
