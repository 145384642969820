import {Component, OnInit} from '@angular/core';
import {BasePageComponent} from '../../core/base-classes/base-page.component';
import {Title} from '@angular/platform-browser';
import {DtoOutAllEmployees, EmployeeService} from '../../generated';

@Component({
  selector: 'app-employees-page',
  templateUrl: './employees-page.component.html',
  styleUrls: ['./employees-page.component.scss']
})
export class EmployeesPageComponent extends BasePageComponent implements OnInit {
  public employees: Array<DtoOutAllEmployees> = [];

  constructor(private title: Title, private employeeService: EmployeeService) {
    super(title, 'Zaměstnanci');
  }

  ngOnInit(): void {
    this.employeeService.apiEmployeeGetAllEmployeesGet().subscribe(data => {
      this.employees = data;
    });
  }

}
