import {Component, Input, NgZone, OnInit} from '@angular/core';
import {BaseEditPageComponent} from '../../core/base-classes/base-edit-page.component';
import {
  DtoInCreateProject,
  DtoInCreateProjectActionWithProject,
  DtoInEditProject, DtoInEditProjectAction, DtoOutAllProjectAction,
  DtoOutProjectInfo,
  ProjectService
} from '../../generated';
import {BaseEditCreateFormComponent} from '../../core/base-classes/base-edit-create-form.component';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../core/services/notifications.service';
import {BehaviorSubject} from 'rxjs';
import {ObjectUtils} from '../../core/objectUtils';
import {Route, Router} from '@angular/router';

@Component({
  selector: 'app-create-edit-project-card',
  templateUrl: './create-edit-project-card.component.html',
  styleUrls: ['./create-edit-project-card.component.scss']
})
export class CreateEditProjectCardComponent extends BaseEditCreateFormComponent<DtoInCreateProject, DtoInEditProject, DtoOutProjectInfo> implements OnInit {
  get projectActions(): DtoOutAllProjectAction[] {
    return this._projectActions;
  }

  @Input()
  set projectActions(value: DtoOutAllProjectAction[]) {
    this._projectActions = value;
    this.createForm();
  }

  get dataForEdit() {
    return this._dataForEdit;
  }

  @Input()
  set dataForEdit(value) {
    this._dataForEdit = value;
    this.createForm();
  }


  private _projectActions: DtoOutAllProjectAction[];

  dataTableSave: DtoInCreateProjectActionWithProject[] = [];
  dataTableEdit: DtoInEditProjectAction[] = [];
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = ['name', 'description', 'delete'];
  rows: FormArray = new FormArray([]);

  private _dataForEdit: DtoOutProjectInfo;

  constructor(private projectService: ProjectService, private notificationsService: NotificationsService, private ngZone: NgZone, private router: Router) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  createForm() {
    if (this.edit) {
      if (this._projectActions) {
        this.rows = new FormArray([]);
        this._projectActions.forEach(item => {
          this.rows.push(new FormGroup({
            'name': new FormControl(item?.name, [Validators.required, Validators.minLength(2), Validators.maxLength(60)]),
            'description': new FormControl(item?.description, [Validators.minLength(2), Validators.maxLength(150)]),
            'id': new FormControl(item?.id),
          }));
        });
      }
      this.form = new FormGroup({
        'name': new FormControl(this._dataForEdit?.name, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
        'description': new FormControl(this._dataForEdit?.description, Validators.maxLength(2000)),
        'projectActions': this.rows
      });
    } else {
      this.form = new FormGroup({
        'name': new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
        'description': new FormControl('', Validators.maxLength(2000)),
        'projectActions': this.rows
      });
    }
    this.updateView();
  }

  getDataCreate(): DtoInCreateProject {
    let result = this.getFormValue() as DtoInCreateProject;
    return result;
  }

  getDataEdit(): DtoInEditProject {
    let result = this.getFormValue() as DtoInEditProject;
    result.projectActions.forEach(item => {
      item.idProject = this._dataForEdit.id;
    });
    return result;
  }

  requestInFormSubmit() {
    if (this.edit) {
      this.projectService.apiProjectEditProjectIdProjectPatch(this._dataForEdit.id, this.getDataEdit()).subscribe(data => {
        this.notificationsService.ok('Úprava projektu proběhla úspěšně.');
        this.btnDisabled = false;
        this.router.navigate([this.ConfigRoutes.myProjecstPage]);
      }, error => {
        this.notificationsService.error('Úprava projektu selhala.');
        this.btnDisabled = false;
      });
    } else {
      this.projectService.apiProjectCreateProjectPost(this.getDataCreate()).subscribe(data => {
        this.notificationsService.ok('Založení projektu proběhlo úspěšně.');
        this.btnDisabled = false;
        this.router.navigate([this.ConfigRoutes.myProjecstPage]);
      }, error => {
        this.notificationsService.error('Založení projektu selhalo.');
        this.btnDisabled = false;
      });
    }
  }

  addRowSave(d: DtoInCreateProjectActionWithProject, noUpdate?: boolean) {
    const row = new FormGroup({
      'name': new FormControl(d?.name, [Validators.required, Validators.minLength(2), Validators.maxLength(60)]),
      'description': new FormControl(d?.description, [Validators.minLength(2), Validators.maxLength(150)]),
    });

    this.rows.push(row);
    if (!noUpdate) {
      this.updateView();
    }
  }


  removeItem(item) {
    ObjectUtils.removeItemFromArray(this.rows.controls, item);
    this.updateView();
    this.form.controls.projectActions.updateValueAndValidity();
  }

  addRow() {
    setTimeout(() => {
      if (this.edit) {
        this.addRowEdit({});
      } else {
        this.addRowSave({});
      }
    });
  }

  addRowEdit(d: DtoInEditProjectAction, noUpdate?: boolean) {
    const row = new FormGroup({
      'name': new FormControl(d?.name, [Validators.required, Validators.minLength(2), Validators.maxLength(60)]),
      'description': new FormControl(d?.description, [Validators.minLength(2), Validators.maxLength(150)]),
      'id': new FormControl(d?.id),
    });
    this.rows.push(row);
    if (!noUpdate) {
      this.updateView();
    }
  }

  updateView() {
    this.dataSource.next(this.rows.controls);
  }

}
