// E= editFormValueOutput  D= EditForm input
import {Input, OnInit, Directive, Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConfigRoutes} from '../config/configRoutes';
//E= editFormValueOutput  D= EditForm input
@Component({template: ''})
export abstract class BaseEditFormComponent<E, D> implements OnInit {
  public form: FormGroup;
  public btnDisabled: boolean = false;
  public ConfigRoutes = ConfigRoutes;
  @Input()
  public dataForEdit: D;

  ngOnInit(): void {
    this.createForm();
  }

  public abstract createForm();

  public formSubmit() {
    if (this.form.valid === true) {
      this.btnDisabled = true;
      this.requestInFormSubmit();
    }
  }

  public getFormValue(raw?: boolean) {
    let value = Object.assign({}, raw ? this.form.getRawValue() : this.form.value); // raw value jsou pro disabled

    return value;
  }

  public abstract requestInFormSubmit();

  public abstract getDataEdit(): E;
}
