/**
 * Working time management
 * Working time management fro emploees of LarvaSystems
 *
 * OpenAPI spec version: 1
 * Contact: vojtech.stor@larvasystems.cz
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DtoInCreateProjectReport { 
    idProject?: number;
    idProjectAction?: number;
    dateFrom?: Date;
    dateTo?: Date;
    description?: string;
}