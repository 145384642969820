import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectInfoService {
  public projectInfoChanged: Subject<void> = new Subject();
  public projectEmployeesChanged: Subject<void> = new Subject();
  constructor() { }
}
