<form [formGroup]="form" (ngSubmit)="formSubmit()">
  <div class="mat-h3" mat-dialog-title> Přidání zaměstnance do projektu</div>
  <div fxLayout="row wrap" >
    <mat-form-field validationMessage>
      <input type="email"
             matInput placeholder="Email zaměstnance" formControlName="email" name="email">
    </mat-form-field>
  </div>
  <div fxLayout="row wrap" class="mt-4">
    <mat-checkbox formControlName="canEdit">Může upravovat projekt?</mat-checkbox>
  </div>

  <div fxLayout="row">
    <div mat-dialog-actions>
      <button mat-raised-button [disabled]="btnDisabled" color="primary">Uložit
      </button>
      <button mat-raised-button color="accent" type="button" (click)="close()">Zavřít</button>
    </div>
  </div>
</form>
