<div fxLayout="row" fxLayoutAlign="center center">
  <div class="mat-h1 mb-0">{{projectInfo?.name}}</div>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <div class="mat-small mb-4">{{projectInfo?.description}}</div>
  <mat-divider class="mt-4"></mat-divider>
</div>
<mat-expansion-panel class="mt-2">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Role projektu
    </mat-panel-title>
    <mat-panel-description>
      Tento panel obsahuje přehled rolí projektu.
    </mat-panel-description>
  </mat-expansion-panel-header>
  <app-project-info-actions-table [projectActions]="projectInfo?.projectActions"
                                  [canEdit]="projectInfo?.canEdit"></app-project-info-actions-table>
</mat-expansion-panel>
