import {Component, OnInit} from '@angular/core';
import {BaseInfoPageComponent} from '../../core/base-classes/base-info-page.component';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {DtoOutEmployeeInfo, EmployeeService} from '../../generated';
import {EmployeeInfoService} from '../../cards/employee-info-card/employee-info.service';

@Component({
  selector: 'app-employee-page',
  templateUrl: './employee-page.component.html',
  styleUrls: ['./employee-page.component.scss']
})
export class EmployeePageComponent extends BaseInfoPageComponent implements OnInit {
  public employee: DtoOutEmployeeInfo;
  public costForBusiness: number;
  public costForEmployee: number;

  constructor(private title: Title, private route: ActivatedRoute, private employeeService: EmployeeService, private employeeInfoService: EmployeeInfoService) {
    super(title, 'Zaměstnanec', route);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getItem(id: number) {
    this.employeeInfoService.employeeInfoChanged.subscribe(() => {
      const oldthis = this;
      this.employeeService.apiEmployeeGetEmployeeInfoIdGet(id).subscribe(data => {
        oldthis.title.setTitle('Zaměstnanec | ' + data.email);
        oldthis.employee = data;
      });
    });
    this.employeeService.apiEmployeeGetEmployeeInfoIdGet(id).subscribe(data => {
      this.title.setTitle('Zaměstnanec | ' + data.email);
      this.employee = data;
    });
  }


}
