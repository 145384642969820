import {Component, Inject, OnInit} from '@angular/core';
import {BaseCreateFormComponent} from '../../core/base-classes/base-create-form.component';
import {DtoInAddUserToProject, DtoOutAllProjectReports, ProjectActionService, ProjectService, TimeService} from '../../generated';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '../../core/services/notifications.service';
import {ProjectInfoService} from '../../cards/project-info-card/project-info.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-employee-to-project-dialog',
  templateUrl: './add-employee-to-project-dialog.component.html',
  styleUrls: ['./add-employee-to-project-dialog.component.scss']
})
export class AddEmployeeToProjectDialogComponent extends BaseCreateFormComponent<DtoInAddUserToProject> implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddEmployeeToProjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { idProject: number }, private notificationsService: NotificationsService, private projectInfoService: ProjectInfoService, private projectService: ProjectService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  createForm() {
    this.form = new FormGroup({
      'idProject': new FormControl(this.data?.idProject),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'canEdit': new FormControl(false)
    });
  }

  getData(): DtoInAddUserToProject {
    let value = this.getFormValue() as DtoInAddUserToProject;
    return value;
  }

  requestInFormSubmit() {
    this.projectService.apiProjectAddUserToProjectPost(this.getData()).subscribe(data => {
      this.notificationsService.ok('Přidání zaměstnance proběhlo úspěšně');
      this.projectInfoService.projectEmployeesChanged.next();
      this.btnDisabled = false;
      this.close();
    }, error => {
      this.btnDisabled = false;
      this.notificationsService.error('Přidání zaměstnance se nezdařilo');
    });
  }

  close() {
    this.dialogRef.close();
  }

}
