import {Component, Inject, OnInit} from '@angular/core';
import {BaseCreateFormComponent} from '../../core/base-classes/base-create-form.component';
import {DtoInAddUserToProject, DtoInAddUserToProjectById, DtoOutAllProject, ProjectService} from '../../generated';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '../../core/services/notifications.service';
import {ProjectInfoService} from '../../cards/project-info-card/project-info.service';
import {EmployeeInfoService} from '../../cards/employee-info-card/employee-info.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-project-to-employee-dialog',
  templateUrl: './add-project-to-employee-dialog.component.html',
  styleUrls: ['./add-project-to-employee-dialog.component.scss']
})
export class AddProjectToEmployeeDialogComponent extends BaseCreateFormComponent<DtoInAddUserToProjectById> implements OnInit {
  public availableProjects: DtoOutAllProject[];

  constructor(public dialogRef: MatDialogRef<AddProjectToEmployeeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { idEmployee: number }, private notificationsService: NotificationsService, private employeeInfoService: EmployeeInfoService, private projectService: ProjectService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.projectService.apiProjectGetAllMyProjectsGet().subscribe(data => {
      this.availableProjects = data.filter(x => x.canEdit === true);
    });
  }

  createForm() {
    this.form = new FormGroup({
      'idEmployee': new FormControl(this.data.idEmployee),
      'idProject': new FormControl('', [Validators.required]),
      'canEdit': new FormControl(false)
    });
  }

  getData(): DtoInAddUserToProjectById {
    let result = this.form.getRawValue() as DtoInAddUserToProjectById;
    if (result.canEdit !== true) {
      result.canEdit = false;
    }
    return result;
  }

  close() {
    this.dialogRef.close();
  }

  requestInFormSubmit() {
    this.projectService.apiProjectAddUserToProjectByIdPost(this.getData()).subscribe(data => {
      this.notificationsService.ok('Zaměstnanec byl přidán do projektu');
      this.btnDisabled = false;
      this.employeeInfoService.employeeInfoChanged.next();
      this.close();
    }, error => {
      this.btnDisabled = false;
      this.notificationsService.error('Zaměstnance se nepodařilo přidat do projektu');
    });
  }

}
