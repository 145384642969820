import {Component, Inject, OnInit} from '@angular/core';
import {BaseEditCreateFormComponent} from '../../core/base-classes/base-edit-create-form.component';
import {
  DtoInCreateProjectReport,
  DtoInUpdateProjectReport, DtoOutAllMyReports, DtoOutAllProject, DtoOutAllProjectAction,
  DtoOutAllProjectReports,
  ProjectActionService, ProjectService,
  TimeService
} from '../../generated';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '../../core/services/notifications.service';
import {ProjectInfoService} from '../../cards/project-info-card/project-info.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateValidator} from '../../core/validators/date-validator';
import {TimeReportChangeNotificationService} from '../../services/changeNotification/time-report-change-notification.service';

@Component({
  selector: 'app-create-edit-time-main-report-dialog',
  templateUrl: './create-edit-time-main-report-dialog.component.html',
  styleUrls: ['./create-edit-time-main-report-dialog.component.scss']
})
export class CreateEditTimeMainReportDialogComponent extends BaseEditCreateFormComponent<DtoInCreateProjectReport, DtoInUpdateProjectReport, DtoOutAllMyReports> implements OnInit {
  public projectActions: Array<DtoOutAllProjectAction> = [];
  public myProjects: Array<DtoOutAllProject> = [];
  public today = new Date();
  public firstDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
  public lastDay = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0,24);

  public get defaultDateTo() {
    let day = new Date();
    day.setHours(10, 0, 0, 0);
    return day;
  }

  public get defaultDateFrom() {
    let day = new Date();
    day.setHours(9, 0, 0, 0);
    return day;
  }

  public get edit() {
    return this.data.edit;
  }

  constructor(public dialogRef: MatDialogRef<CreateEditTimeMainReportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { idProjectReport: number, edit: boolean, projectReport: DtoOutAllMyReports }, private timeService: TimeService,
              private projectActionService: ProjectActionService, private timeReportChangeNotificationService: TimeReportChangeNotificationService, private projectService: ProjectService, private notificationsService: NotificationsService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadProjects();
  }

  loadProjects() {
    this.projectService.apiProjectGetAllMyProjectsGet().subscribe(data => {
      this.myProjects = data;
    });
  }

  loadProjectActions(idProject: number) {
    this.projectActionService.apiProjectActionGetAllProjectActionsForProjectIdProjectGet(idProject).subscribe(data => {
      this.projectActions = data;
      this.form.updateValueAndValidity();
    });
  }

  createForm() {
    if (this.edit) {
      this.loadProjectActions(this.data?.projectReport.projectInfo?.id);
      this.form = new FormGroup({
        'id': new FormControl(this.data?.idProjectReport),
        'idProject': new FormControl(this.data?.projectReport.projectInfo?.id, [Validators.required]),
        'idProjectAction': new FormControl(this.data?.projectReport?.projectAction?.id, [Validators.required]),
        'description': new FormControl(this.data?.projectReport?.description, [Validators.maxLength(200)]),
        'date': new FormControl(this.data?.projectReport?.dateTo, [Validators.required]),
        'dateToTime': new FormControl(new Date(this.data?.projectReport?.dateTo), [Validators.required]),
        'dateFromTime': new FormControl(new Date(this.data?.projectReport?.dateFrom), [Validators.required])
      });
    } else {
      this.form = new FormGroup({
        'idProject': new FormControl('', [Validators.required]),
        'idProjectAction': new FormControl('', [Validators.required]),
        'description': new FormControl('', [Validators.maxLength(200)]),
        'date': new FormControl(new Date(), [Validators.required]),
        'dateToTime': new FormControl(this.defaultDateTo, [Validators.required]),
        'dateFromTime': new FormControl(this.defaultDateFrom, [Validators.required])
      });
    }
    this.form.controls.idProject.valueChanges.subscribe(dataChange => {
      this.loadProjectActions(this.form.controls.idProject.value);
    });
  }

  close() {
    this.dialogRef.close();
  }

  getDataCreate(): DtoInCreateProjectReport {
    let formValue = this.getFormValue();
    const dateFrom = new Date(formValue.date);
    dateFrom.setHours(new Date(formValue.dateFromTime).getHours(), new Date(formValue.dateFromTime).getMinutes(), 0, 0);
    const dateTo = new Date(formValue.date);
    dateTo.setHours(new Date(formValue.dateToTime).getHours(), new Date(formValue.dateToTime).getMinutes(), 0, 0);
    let result: DtoInCreateProjectReport = {
      idProject: formValue.idProject,
      idProjectAction: formValue.idProjectAction,
      description: formValue.description,
      dateFrom,
      dateTo
    };
    return result;
  }

  getDataEdit(): DtoInUpdateProjectReport {
    let formValue = this.getFormValue();
    const dateFrom = new Date(formValue.date);
    dateFrom.setHours(new Date(formValue.dateFromTime).getHours(), new Date(formValue.dateFromTime).getMinutes(), 0, 0);
    const dateTo = new Date(formValue.date);
    dateTo.setHours(new Date(formValue.dateToTime).getHours(), new Date(formValue.dateToTime).getMinutes(), 0, 0);
    let result: DtoInUpdateProjectReport = {
      id: formValue.id,
      idProject: formValue.idProject,
      idProjectAction: formValue.idProjectAction,
      description: formValue.description,
      dateFrom,
      dateTo
    };
    return result;
  }

  requestInFormSubmit() {
    if (this.edit) {
      this.timeService.apiTimeUpdateProjectReportPatch(this.getDataEdit()).subscribe(data => {
        this.notificationsService.ok('Výkaz byl upraven');
        this.btnDisabled = false;
        this.timeReportChangeNotificationService.timeReportChanges.next();
        this.close();
      }, error => {
        this.notificationsService.error('Výkaz se nepodařilo upravit');
        this.btnDisabled = false;
      });
    } else {
      this.timeService.apiTimeCreateProjectReportPost(this.getDataCreate()).subscribe(data => {
        this.notificationsService.ok('Výkaz byl přidán');
        this.btnDisabled = false;
        this.timeReportChangeNotificationService.timeReportChanges.next();
        this.close();
      }, error => {
        this.notificationsService.error('Výkaz se nepodařilo přidat');
        this.btnDisabled = false;
      });
    }
  }

}
