import {Component, Inject, OnInit} from '@angular/core';
import {BaseEditCreateFormComponent} from '../../core/base-classes/base-edit-create-form.component';
import {CostService, DtoInCreateCostForEmployee, DtoInCreateProject, DtoInUpdateCostForEmployee, DtoOutEmployeeCost} from '../../generated';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '../../core/services/notifications.service';
import {EmployeeInfoService} from '../../cards/employee-info-card/employee-info.service';

@Component({
  selector: 'app-create-cost-dialog',
  templateUrl: './create-cost-dialog.component.html',
  styleUrls: ['./create-cost-dialog.component.scss']
})
export class CreateCostDialogComponent extends BaseEditCreateFormComponent<DtoInCreateCostForEmployee, DtoInUpdateCostForEmployee, DtoOutEmployeeCost> implements OnInit {


  constructor(public dialogRef: MatDialogRef<CreateCostDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { employeeId: number, edit: boolean, employeeCost: DtoOutEmployeeCost }, private costService: CostService, private notificationsService: NotificationsService, private employeeInfoService: EmployeeInfoService) {
    super();
  }

  public get edit() {
    return this.data.edit;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  createForm() {
    if (this.edit) {
      this.form = new FormGroup({
        'id': new FormControl(this.data?.employeeCost.id),
        'costForHim': new FormControl(this.data?.employeeCost?.costForHim, [Validators.required, Validators.min(0)]),
        'costForBusiness': new FormControl(this.data?.employeeCost?.costForBusiness, [Validators.required, Validators.min(0)]),
        'dateFrom': new FormControl(this.data?.employeeCost?.dateFrom, [Validators.required]),
        'dateTo': new FormControl(this.data?.employeeCost?.dateTo)
      });
    } else {
      this.form = new FormGroup({
        'employeeId': new FormControl(this.data?.employeeId),
        'costForHim': new FormControl('', [Validators.required, Validators.min(0)]),
        'costForBusiness': new FormControl('', [Validators.required, Validators.min(0)]),
        'dateFrom': new FormControl(new Date(), [Validators.required]),
        'dateTo': new FormControl(null)
      });
    }
  }

  getDataCreate(): DtoInCreateCostForEmployee {
    let result = this.getFormValue() as DtoInCreateCostForEmployee;
    result.dateFrom = new Date(result.dateFrom);
    result.dateFrom.setHours(0, 0, 0, 0);
    if (result.dateTo) {
      result.dateTo = new Date(result.dateTo);
      result.dateTo.setHours(0, 0, 0, 0);
    }
    return result;
  }

  getDataEdit(): DtoInUpdateCostForEmployee {
    let result = this.getFormValue() as DtoInUpdateCostForEmployee;
    result.dateFrom = new Date(result.dateFrom);
    result.dateFrom.setHours(0, 0, 0, 0);
    if (result.dateTo) {
      result.dateTo = new Date(result.dateTo);
      result.dateTo.setHours(0, 0, 0, 0);
    }
    return result;
  }

  requestInFormSubmit() {
    if (this.edit) {
      this.costService.apiCostUpdateCostPatch(this.getDataEdit()).subscribe(data => {
        this.notificationsService.ok('Sazba byla úspěšně upravena');
        this.btnDisabled = false;
        this.employeeInfoService.employeeInfoChanged.next();
        this.close();
      }, error => {
        this.btnDisabled = false;
        this.notificationsService.error('Sazbu se neporařilo upravit');
      });
    } else {
      this.costService.apiCostCreateCostPost(this.getDataCreate()).subscribe(data => {
        this.notificationsService.ok('Sazba byla úspěšně vytvořena');
        this.btnDisabled = false;
        this.employeeInfoService.employeeInfoChanged.next();
        this.close();
      }, error => {
        this.notificationsService.error('Sazbu se neporařilo vytvořit');
        this.btnDisabled = false;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
