<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{employee?.firstName}} {{employee?.lastName}}</mat-card-title>
      <mat-card-subtitle>{{employee?.email}}</mat-card-subtitle>
      <div mat-card-avatar><img [src]="employee?.pictureUrl" width="40" class="profile-photo"/></div>
    </mat-card-header>
    <mat-card-content>
      <app-employee-costs [employeeCosts]="employee?.employeeCosts" [employeeId]="employee?.id"></app-employee-costs>
      <app-employee-projects [projectEmployees]="employee?.projectEmployees"
                             [idEmployee]="employee?.id"></app-employee-projects>
    </mat-card-content>
  </mat-card>
</div>
