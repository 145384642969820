import {Component, OnInit} from '@angular/core';
import {BaseInfoPageComponent} from '../../core/base-classes/base-info-page.component';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {BasePageComponent} from '../../core/base-classes/base-page.component';

@Component({
  selector: 'app-create-project-page',
  templateUrl: './create-project-page.component.html',
  styleUrls: ['./create-project-page.component.scss']
})
export class CreateProjectPageComponent extends BasePageComponent implements OnInit {

  constructor(private title: Title) {
    super(title, 'Vytvoření projektu');
  }

  ngOnInit(): void {
  }

}
