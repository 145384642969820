import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtoOutAllProject, ProjectService} from '../../generated';
import {ConfirmationDialogService} from '../../core/services/confirmation-dialog.service';
import {dashCaseToCamelCase} from '@angular/compiler/src/util';
import {NotificationsService} from '../../core/services/notifications.service';
import {Route, Router} from '@angular/router';
import {ConfigRoutes} from '../../core/config/configRoutes';
import {Config} from 'codelyzer';

@Component({
  selector: 'app-project-list-table-card',
  templateUrl: './project-list-table-card.component.html',
  styleUrls: ['./project-list-table-card.component.scss']
})
export class ProjectListTableCardComponent implements OnInit {
  @Input()
  public projects: DtoOutAllProject[];
  @Output()
  public projectRemoved: EventEmitter<void> = new EventEmitter<void>();

  constructor(private confirmationDialogService: ConfirmationDialogService, private projectService: ProjectService, private notificationsService: NotificationsService, private router: Router) {
  }

  ngOnInit(): void {
  }

  infoProject(id: number) {
  this.router.navigate([ConfigRoutes.projectPage,id]);
  }

  editProject(id: number) {
    this.router.navigate([ConfigRoutes.editProjectPage, id]);
  }

  deleteProject(id: number) {
    let projectName = this.projects.find(x => x.id === id).name;
    this.confirmationDialogService.confirmDialog('Opravdu si přejete smazat projekt ' + projectName).subscribe(answer => {
      if (answer) {
        const oldthis = this;
        this.projectService.apiProjectDeleteProjectIdProjectDelete(id).subscribe(data => {
          oldthis.notificationsService.ok('Smazání projektu ' + projectName + ' proběhlo úspěšně');
          oldthis.projectRemoved.emit();
        }, error => {
          oldthis.notificationsService.error('Smazání projektu ' + projectName + ' se nezdařilo');
        });
      }
    });
  }
}
