import {Component, Input, OnInit} from '@angular/core';
import {DtoOutProjectInfo} from '../../generated';

@Component({
  selector: 'app-project-info-card',
  templateUrl: './project-info-card.component.html',
  styleUrls: ['./project-info-card.component.scss']
})
export class ProjectInfoCardComponent implements OnInit {
  @Input()
  public projectInfo: DtoOutProjectInfo;

  constructor() {
  }

  ngOnInit(): void {
  }

}
