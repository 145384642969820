<form [formGroup]="form" (ngSubmit)="formSubmit()">
  <h1 mat-dialog-title> {{edit === true ? 'Editace hodinové sazby' : 'Vytvoření hodinové sazby' }}</h1>
  <div>
    <div>
      <mat-form-field validationMessage>
        <input type="number"
               matInput placeholder="Hodinová sazba pro zaměstnance" formControlName="costForHim" name="costForHim">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field validationMessage>
        <input type="number"
               matInput placeholder="Hodinová sazba pro firmu" formControlName="costForBusiness" name="costForBusiness">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field validationMessage>
        <input
          matInput placeholder="Datum od" formControlName="dateFrom" name="dateFrom" [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field validationMessage>
        <input
          matInput placeholder="Datum do" formControlName="dateTo" name="dateTo" [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="btnDisabled" color="primary">Uložit
    </button>
    <button mat-raised-button color="accent" type="button" (click)="close()">Zavřít</button>
  </div>
</form>
