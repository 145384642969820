import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DtoOutAllEmployees, DtoOutProjectAction, ProjectActionService} from '../../../generated';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ConfirmationDialogService} from '../../../core/services/confirmation-dialog.service';
import {NotificationsService} from '../../../core/services/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {EditProjectRoleDialogComponent} from '../../../dialogs/edit-project-role-dialog/edit-project-role-dialog.component';
import {ProjectInfoService} from '../project-info.service';

@Component({
  selector: 'app-project-info-actions-table',
  templateUrl: './project-info-actions-table.component.html',
  styleUrls: ['./project-info-actions-table.component.scss']
})
export class ProjectInfoActionsTableComponent implements OnInit {
  get canEdit(): boolean {
    return this._canEdit;
  }

  @Input()
  set canEdit(value: boolean) {
    this._canEdit = value;
    if (value) {
      this.displayedColumns = ['name', 'description', 'edit', 'delete'];
    } else {
      this.displayedColumns = ['name', 'description'];
    }
  }

  private _canEdit: boolean;

  get projectActions(): Array<DtoOutProjectAction> {
    return this._projectActions;
  }

  @Input()
  set projectActions(value: Array<DtoOutProjectAction>) {
    this._projectActions = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.data = value;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutProjectAction>;
  displayedColumns = ['name', 'description', 'edit', 'delete'];

  private _projectActions: Array<DtoOutProjectAction>;

  constructor(private confirmationDialogService: ConfirmationDialogService, private notificationsService: NotificationsService, private projectInfoService: ProjectInfoService, private projectActionService: ProjectActionService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  edit(id: number) {
    this.dialog.open(EditProjectRoleDialogComponent, {data: {projectAction: this.projectActions.find(x => x.id === id)}});
  }

  remove(id: number, roleName: string) {
    this.confirmationDialogService.confirmDialog('Opravdu si přejete smazat roli: ' + roleName).subscribe(data => {
      if (data === true) {
        const oldthis = this;
        this.projectActionService.apiProjectActionDeleteProjectActionIdProjectActionDelete(id).subscribe(data => {
          oldthis.notificationsService.ok('Role ' + roleName + ' byla úspěšně smazána');
          oldthis.projectInfoService.projectInfoChanged.next();
        }, error => {
          oldthis.notificationsService.error('Roli ' + roleName + ' se nepodařilo smazat');
        });
      }
    });
  }
}
