import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DtoOutAllEmployees} from '../../generated';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {ConfigRoutes} from '../../core/config/configRoutes';

@Component({
  selector: 'app-employee-list-card',
  templateUrl: './employee-list-card.component.html',
  styleUrls: ['./employee-list-card.component.scss']
})
export class EmployeeListCardComponent implements OnInit {
  get employees(): Array<DtoOutAllEmployees> {
    return this._employees;
  }

  @Input()
  set employees(value: Array<DtoOutAllEmployees>) {
    this._employees = value;
    if (value) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.data = value;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  private _employees: Array<DtoOutAllEmployees> = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource: MatTableDataSource<DtoOutAllEmployees>;
  displayedColumns = ['firstName', 'lastName', 'email', 'View'];

  constructor(private router: Router) {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
  }

  view(id: number) {
    this.router.navigate([ConfigRoutes.employee, id]);
  }
}
