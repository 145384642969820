<div fxLayout="row wrap" fxLayoutAlign="start center">
  <div *ngFor="let item of projects">
    <app-card-wrapper>
      <mat-card class="project-card mt-2 ml-2 mr-2">
        <mat-card-header>
          <mat-card-title class="pt-2">{{item.name}}</mat-card-title>
          <mat-card-subtitle>{{item.description}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <div fxLayout="row wrap" fxLayoutAlign="center end">
            <button mat-stroked-button (click)="infoProject(item.id)" color="primary">Info</button>
              <ng-container *ngIf="item.canEdit">
                <button mat-stroked-button (click)="editProject(item.id)" color="accent">Upravit</button>
              </ng-container>
              <ng-container *hasAccessToView="['ProjectManager','WorkingTimeManagementAdmin']">
                <ng-container *ngIf="item.canEdit">
                  <button mat-stroked-button (click)="deleteProject(item.id)" color="warn">Smazat</button>
                </ng-container>
              </ng-container>
          </div>
        </mat-card-actions>
      </mat-card>
    </app-card-wrapper>
  </div>
</div>
